export type Event = {
  details: Details
  integration: IntegrationInfo
  event: EventInfo
  messages: unknown[]
  comments: unknown[]
}

export type EventInfo = {
  numberOfErrors: string
  numberOfWarnings: string
  completionDate: string
  level: string
  status: string
  schedule: string
  isRepeat?: boolean
}

export type Details = {
  user: string
  workdayId: string
  tenantId: string
  referenceId: string
}

export type IntegrationInfo = {
  system: string
  event: string
}

export enum EventLevel {
  Completed = 'Completed',
  CompletedWithWarnings = 'Completed with Warnings',
  CompletedWithErrors = 'Completed With Errors',
  Failed = 'Failed',
}

export default Event
