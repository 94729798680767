/* eslint-disable import/no-anonymous-default-export */
import {
  Card,
  Metric,
  Text,
  Flex,
  BadgeDelta,
  Color,
  Grid,
  DeltaType,
} from '@tremor/react'
import { useContext } from 'react'
import { QueryFunctionContext, useQuery } from 'react-query'
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../../../../layout/Application'
import Api from '../../../../../util/api'
import { Level } from '../../../types'

const colors: { [key: string]: Color } = {
  increase: 'emerald',
  moderateIncrease: 'emerald',
  unchanged: 'orange',
  moderateDecrease: 'rose',
  decrease: 'rose',
}

const calculateColor = (level: string, trend: string) => {
  switch (level) {
    case Level.Completed:
      if (trend === 'decrease') {
        return 'rose'
      } else {
        return 'emerald'
      }

    case Level.Failed:
    case Level.CompletedWithErrors:
    case Level.CompletedWithWarnings:
      if (trend === 'decrease') {
        return 'emerald'
      } else {
        return 'rose'
      }

    default: {
      return 'orange'
    }
  }
}

export enum Period {
  Monthly = 'PERIOD_MONTHLY',
}

const fetchStatistics = async (
  context: QueryFunctionContext<
    [key: string, period: string, level: string, tenant?: string, range?: any]
  >
): Promise<any> => {
  const [_, period, level, tenant, range] = context.queryKey
  const data = await Api.post<{ data: object }>('v1/stats', {
    period,
    level,
    tenant,
    range,
  })

  return data?.data
}

export default () => {
  const { getTenantID, getDateRange } = useContext(
    ApplicationContext
  ) as ApplicationContextProps

  const tenant = getTenantID()
  const range = getDateRange()

  const completedStats = useQuery(
    ['get-statistics', Period.Monthly, 'Completed', tenant, range],
    fetchStatistics
  )

  const warningStats = useQuery(
    [
      'get-statistics',
      Period.Monthly,
      'Completed with Warnings',
      tenant,
      range,
    ],
    fetchStatistics
  )

  const completedWithErrorStats = useQuery(
    ['get-statistics', Period.Monthly, 'Completed With Errors', tenant, range],
    fetchStatistics
  )

  const failureStats = useQuery(
    ['get-statistics', Period.Monthly, 'Failed', tenant, range],
    fetchStatistics
  )

  const renderCategories = () => {
    const categories: unknown[] = []

    if (completedStats.status !== 'loading' && completedStats.data) {
      categories.push({
        ...completedStats.data,
        color: calculateColor(Level.Completed, completedStats.data?.trend),
        title: 'Completed',
        negative: true,
      })
    }

    if (warningStats.status !== 'loading' && warningStats.data) {
      categories.push({
        ...warningStats.data,
        color: calculateColor(
          Level.CompletedWithWarnings,
          warningStats.data?.trend
        ),
        title: 'Completed with Warnings',
        negative: false,
      })
    }

    if (
      completedWithErrorStats.status !== 'loading' &&
      completedWithErrorStats.data
    ) {
      categories.push({
        ...completedWithErrorStats.data,
        color: calculateColor(
          Level.CompletedWithErrors,
          completedWithErrorStats.data?.trend
        ),
        title: 'Completed with Errors',
        negative: false,
      })
    }

    if (failureStats.status !== 'loading' && failureStats.data) {
      categories.push({
        ...failureStats.data,
        color: calculateColor(Level.Failed, failureStats.data?.trend),
        title: 'Failed',
        negative: false,
      })
    }

    return categories as {
      title: string
      current: number
      previous: number
      trend: DeltaType
      color: any
      delta: number
      negative: boolean
    }[]
  }

  return (
    <Grid numColsSm={2} numColsLg={4} className="gap-x-3 gap-y-6">
      {renderCategories().map(item => (
        <Card key={item.title}>
          <Text>{item.title}</Text>
          <Flex
            justifyContent="start"
            alignItems="baseline"
            className="space-x-3 truncate"
          >
            <Metric>{item.current}</Metric>
            <Text className="truncate">from {item.previous}</Text>
          </Flex>
          <Flex justifyContent="start" className="space-x-2 mt-4">
            <BadgeDelta
              deltaType={item.trend}
              isIncreasePositive={item?.negative}
            />
            <Flex justifyContent="start" className="space-x-1 truncate">
              <Text color={item?.color}>{item.delta}</Text>
              <Text className="truncate"> to previous month </Text>
            </Flex>
          </Flex>
        </Card>
      ))}
    </Grid>
  )
}
