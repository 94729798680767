import { QueryFunctionContext, QueryKey } from 'react-query'
import Api from '../../../../util/api'
import Event from '../../../../types/event'

export const fetchEvents = async (
  context: QueryFunctionContext<
    [key?: string, page?: number, limit?: number, tenant?: string, range?: any]
  >
) => {
  const {
    queryKey: [_, page = 1, limit = 10, tenant, range],
  } = context

  return (
    await Api.post<{ data: Event[] }>(
      'v1/event',
      { tenant, range },
      { params: { limit, page } }
    )
  )?.data
}

export const fetchPaginatedEvents = async (
  page: number,
  limit: number,
  params?: any
) => {
  const { tenant, range, query, level } = params

  return await Api.post<{ data: Event[] }>(
    'v1/event',
    { tenant, range, query, level },
    {
      params: { limit, page },
    }
  )
}
