/* eslint-disable import/no-anonymous-default-export */
import { Card, Title, BarChart } from '@tremor/react'

export default ({ data = [] }: { data: any[] }) => {
  return (
    <Card>
      <Title>Integration Events - Bar Chart</Title>
      <BarChart
        data={data}
        index="topic"
        categories={['Completed', 'Warnings', 'Errors', 'Failed']}
        colors={['green', 'yellow', 'orange', 'red', 'indigo', 'emerald']}
        className="mt-6"
        yAxisWidth={48}
      />
    </Card>
  )
}
