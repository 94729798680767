import { Card, Flex } from '@tremor/react'
import OnboardingCard from './card'
import { MouseEventHandler, useState } from 'react'
import { WorkdayCredentialsSecrets, createTenant } from '../http'

const IDS = {
  ['Tenant Id']: 'tenant-id',
  ['Tenant Name']: 'tenant-name',
  ['Client Id']: 'client-id',
  ['Client Secret']: 'client-secret',
  ['Refresh Token']: 'refresh-token',
  ['Workday Auth URL']: 'access-token-url',
  ['WQL Api URL']: 'wql-api-url',
} as const

// empty strings are used for the default values
const DEFAULTS = Object.values(IDS).reduce(
  (acc, id) => ({ ...acc, [id]: '' }),
  {}
) as Record<(typeof IDS)[keyof typeof IDS], string>

type TestResult = {
  ran: boolean
  success: boolean
  message: string
}

export default ({
  user,
  org,
  onContinue,
}: {
  user: string | undefined
  org: string
  onContinue: () => void
}): JSX.Element => {
  const [values, setValues] = useState(DEFAULTS)
  const [errors, setErrors] = useState(DEFAULTS)
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState({
    ran: false,
    success: false,
    message: '',
  })

  let submitButtonIsEnabled: boolean
  if (isLoading) {
    submitButtonIsEnabled = false
  } else {
    const hasEmptyValues = Object.values(values).some(v => v === '')
    if (hasEmptyValues) {
      submitButtonIsEnabled = false
    } else {
      const hasErrors = Object.values(errors).some(e => e !== '')
      submitButtonIsEnabled = !hasErrors
    }
  }

  const onSubmit: MouseEventHandler<HTMLButtonElement> = async e => {
    e.preventDefault()
    if (!submitButtonIsEnabled) throw new Error('Button is not enabled')

    const secrets = { ...values } as WorkdayCredentialsSecrets
    delete (secrets as Partial<typeof values>)['tenant-id']
    delete (secrets as Partial<typeof values>)['tenant-name']

    const data = {
      details: {
        name: values[IDS['Tenant Name']],
        id: values[IDS['Tenant Id']],
        organisation: org,
      },
      client: {
        type: 'WQL' as const,
        ...secrets,
      },
    }

    setIsLoading(true)
    const response = await createTenant(data)
    response.success
      ? onContinue()
      : setResults({
          success: false,
          ran: true,
          message: 'Failed to save Tenant credentials: ' + response.message,
        })

    setIsLoading(false)
  }

  return (
    <OnboardingCard email={user}>
      <h2 className="text-lg font-bold mb-6 underline">
        Required Workday Credentials
      </h2>
      <form className="flex-col ">
        <_Input
          name="Tenant Name"
          placeholder="My Tech Company"
          validate={v =>
            v.length < 2
              ? 'Tenant Name must be at least 2 characters long'
              : undefined
          }
          values={values}
          setValues={v =>
            setValues({
              ...v,
              [IDS['Tenant Id']]: (v as typeof values)[IDS['Tenant Name']]
                .replace(/ /g, '_')
                .toLowerCase(),
            } as typeof values)
          }
          errors={errors}
          setErrors={setErrors}
        />
        <_Input
          name="Tenant Id"
          placeholder="my-tech-company"
          validate={v =>
            v.length < 2
              ? 'Tenant Id must be at least 2 characters long'
              : v.includes(' ')
              ? 'Tenant Id cannot contain spaces'
              : !v.match(/[a-z0-9_]+/)
              ? 'Tenant Id can only contain lowercase letters and underscores'
              : undefined
          }
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
        />
        <hr className="mb-4 mt-2" />
        <_Input
          name="Client Id"
          placeholder="6779ef20e75817b79602"
          validate={v =>
            v.length < 10
              ? 'Client Id must be at least 10 characters long'
              : undefined
          }
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
        />
        <_Input
          name="Client Secret"
          placeholder="58650c765a0e419555aa53c834909e7a32ba591f6bdd3670ff40a93c"
          validate={v =>
            v.length < 28
              ? 'Client Secret must be at least 28 characters long'
              : undefined
          }
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
          isPassword
        />
        <_Input
          name="Refresh Token"
          placeholder="RjY2NjM5NzA2OWJjuE7c"
          validate={v =>
            v.length < 10
              ? 'Refresh Token must be at least 10 characters long'
              : undefined
          }
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
          isPassword
        />
        <_Input
          name="Workday Auth URL"
          placeholder="https://...workday.com/ccx/oauth2/tenant_name/token"
          validate={v =>
            v.length < 30
              ? 'Workday Auth URL must be at least 30 characters long'
              : undefined
          }
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
          isUrl
        />
        <_Input
          name="WQL Api URL"
          placeholder="https://...workday.com/ccx/api/wql/v1/tenant_name/data"
          validate={v =>
            v.length < 30
              ? 'WQL Api URL must be at least 30 characters long'
              : undefined
          }
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
          isUrl
        />
        <_SubmitButton
          isEnabled={submitButtonIsEnabled}
          testResult={results}
          onClick={onSubmit}
          isLoading={isLoading}
        />
      </form>
    </OnboardingCard>
  )
}

const _Input = ({
  name,
  placeholder,
  isUrl,
  values,
  setValues,
  errors,
  setErrors,
  validate,
  id = IDS[name],
  isPassword,
}: {
  name: keyof typeof IDS
  placeholder: string
  isUrl?: boolean
  isPassword?: boolean
  validate?: (value: string) => string | void
  values: Record<(typeof IDS)[keyof typeof IDS], string>
  setValues: React.Dispatch<
    React.SetStateAction<Record<(typeof IDS)[keyof typeof IDS], string>>
  >
  errors: Record<(typeof IDS)[keyof typeof IDS], string>
  setErrors: React.Dispatch<
    React.SetStateAction<Record<(typeof IDS)[keyof typeof IDS], string>>
  >
  id?: (typeof IDS)[keyof typeof IDS]
}) => (
  <Flex className="mb-2" title={errors[id] ? errors[id] : undefined}>
    <label
      className={
        'block text-gray-700 text-sm text-left font-bold m-2 w-52' +
        (errors[id] ? ' text-red-500' : '')
      }
      htmlFor={id}
    >
      {name}
      <span className="text-red-500">*</span>
    </label>
    <input
      className={
        'w-full shadow appearance-none border rounded w-full py-1 px-2 leading-tight focus:outline-none focus:shadow-outline' +
        (errors[id] ? ' border-red-500' : '')
      }
      type={isUrl ? 'url' : isPassword ? 'password' : 'text'}
      placeholder={placeholder}
      required
      value={values[id]}
      onChange={e => {
        setErrors({
          ...errors,
          [id]:
            validate?.(e.target.value) ??
            (!e.target.reportValidity() ? 'Invalid Input' : ''),
        })

        setValues({ ...values, [id]: e.target.value })
      }}
      id={id}
    />
  </Flex>
)

const _SubmitButton = ({
  isEnabled,
  testResult,
  onClick,
  isLoading,
}: {
  isEnabled: boolean
  testResult: TestResult
  onClick: MouseEventHandler<HTMLButtonElement>
  isLoading: boolean
}) => (
  <Flex
    flexDirection="col"
    className="w-full mt-6"
    justifyContent="center"
    alignItems="center"
  >
    <button
      type="submit"
      className={
        'bg-blue-500 text-white font-bold py-2 px-4 rounded' +
        (isEnabled
          ? ' hover:bg-blue-700 focus:shadow-outline'
          : ' opacity-50') +
        (testResult.ran && testResult.success
          ? ' bg-green-500 hover:bg-green-700'
          : '')
      }
      disabled={!isEnabled}
      onClick={onClick}
    >
      {isLoading ? '...loading' : 'Continue'}
    </button>
    <Flex className="mt-4">
      {testResult.ran && !testResult.success && (
        <Card className="bg-yellow-200">
          <h4>INVALID CREDENTIALS:</h4>
          {testResult.message}
        </Card>
      )}
    </Flex>
  </Flex>
)
