/**
 * A full-width horizontal divider.
 *
 * @component
 */
export default () => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center"></div>
    </div>
  )
}
