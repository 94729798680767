import { Fragment, useMemo, useRef, useState } from 'react'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { Icon } from '@tremor/react'
import { Dialog, Transition } from '@headlessui/react'
import Datepicker from 'react-tailwindcss-datepicker'
import { DateRangeType } from 'react-tailwindcss-datepicker/dist/types'
import { useQuery, QueryFunctionContext, useMutation } from 'react-query'
import { Formik, Form, Field } from 'formik'

import Api from '../../../util/api'

type _NotificationSchedule = {
  name: string
  cycle: string
  tenant: string
  recipient: string
  active: boolean
}

/**
 * Component for the modal that appears when the user clicks the "Create New Schedule" button.
 *
 * @component
 */
export default function ({
  isOpen,
  close,
}: {
  isOpen: boolean
  close: () => void
}) {
  // init consts
  const cancelButtonRef = useRef(null)

  const { mutateAsync } = useMutation({
    mutationFn: async (tenant: _NotificationSchedule) => {
      const response = await Api.post('v1/notifications/schedule', {
        ...tenant,
      })
      return response
    },
  })

  const getTenants = async (
    context: QueryFunctionContext<[key?: string, id?: string]>
  ): Promise<any | undefined> => {
    const response = await Api.get<{ status: string; data: any[] }>('v1/tenant')
    return response.data
  }

  const { data: response, status } = useQuery(
    ['notification-modal'],
    getTenants
  )

  const tenants = useMemo(() => {
    if (typeof response === 'undefined') {
      return ['']
    } else {
      const mapped_tenants = response?.map((tenant: any) => ({
        value: tenant.id,
        label: tenant?.name,
      }))
      return mapped_tenants
    }
  }, [status])

  // compose jsx
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      {/* Modal Container */}
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Modal Contents */}
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <Formik
                  initialValues={{
                    name: '',
                    cycle: 'days',
                    tenant: tenants[0]?.value,
                    recipient: '',
                    active: false,
                  }}
                  onSubmit={async values => {
                    await mutateAsync(values)
                    close()
                  }}
                >
                  <Form>
                    <div>
                      <div className="mt-3 text-center sm:mt-5">
                        {/* Modal Title */}
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900 border-b border-gray-900/10 pb-2"
                        >
                          Create a New Email Schedule
                        </Dialog.Title>
                        <div className="text-left border-b border-gray-900/10 pb-12">
                          <>
                            <_FormRow>
                              <Field
                                as={_Input}
                                name="Name"
                                type="text"
                                desc="Name of schedule"
                              />
                              <Field
                                as={_Select}
                                name="Cycle"
                                desc="Name of schedule"
                                options={[
                                  { label: 'Days', value: 'days' },
                                  { label: 'Weeks', value: 'weeks' },
                                  { label: 'Months', value: 'months' },
                                ]}
                              />
                            </_FormRow>
                            <_FormRow>
                              <Field
                                as={_Select}
                                name="Tenant"
                                desc="The tenant this job belongs to"
                                options={tenants}
                              />
                              <Field
                                as={_Input}
                                name="Recipient"
                                type="text"
                                desc="The emails the notifications should be sent to."
                              />
                            </_FormRow>
                            <_FormRow>
                              <Field
                                as={_Select}
                                name="Active"
                                desc="Whether or not this job is active/will run on schedule."
                                options={[
                                  { label: 'No (inactive)', value: false },
                                  { label: 'Yes (active)', value: true },
                                ]}
                              />
                            </_FormRow>
                          </>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={close}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </Formik>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

/** @internal */
const _FormRow = ({
  children,
}: {
  children: JSX.Element | JSX.Element[]
}): JSX.Element => (
  <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
    {children}
  </div>
)

/** @internal */
const _Label = ({
  name,
  desc,
  required = false,
}: {
  name: string
  desc: string
  required?: boolean
}): JSX.Element => (
  <label
    title={desc}
    htmlFor={_getIdFromName(name)}
    className="block text-sm font-medium leading-6 text-gray-900"
  >
    {required && <span color="red">* </span>}
    {name}
    <Icon icon={QuestionMarkCircleIcon} size="xs" />
  </label>
)

// utility component
function _Input({
  name,
  desc,
  required = false,
  type = 'text',
  ...props
}: {
  name: string
  desc: string
  type?: React.HTMLInputTypeAttribute
  required?: boolean
}): JSX.Element {
  // init consts
  const id = _getIdFromName(name)

  // compose jsx
  return (
    <div className="sm:col-span-3">
      <_Label name={name} desc={desc} required={required} />
      <div>
        <input
          type={type}
          name={id}
          id={id}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder={desc}
          required={required}
          {...props}
        />
      </div>
    </div>
  )
}

/** @internal */
function _Select({
  field,
  name,
  options,
  desc,
  defaultIndex = 0,
  required = false,
  ...props
}: any): JSX.Element {
  // init consts
  const id = _getIdFromName(name)

  // compose jsx
  return (
    <div className="sm:col-span-3">
      <_Label name={name} desc={desc} required={required} />
      <div>
        <select
          id={id}
          name={id}
          defaultValue={options[defaultIndex]}
          placeholder={desc}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          required={required}
          {...field}
          {...props}
        >
          {options.map(
            (option: { value: string; label: string }, index: number) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            )
          )}
        </select>
      </div>
    </div>
  )
}

/** @internal */
function _Datepicker({
  name,
  desc,
  required = false,
}: {
  name: string
  desc: string
  required: boolean
}): JSX.Element {
  // init consts
  const id = _getIdFromName(name)
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  // init state
  const [date, setDate] = useState<DateRangeType | null>({
    startDate: null,
    endDate: null,
  })

  // compose jsx
  return (
    <div className="sm:col-span-3">
      <_Label name={name} desc={desc} required={required} />
      <Datepicker
        inputId={id}
        inputName={id}
        primaryColor="blue"
        value={date}
        placeholder={desc}
        onChange={setDate}
        asSingle={true}
        minDate={yesterday}
        useRange={false}
      />
    </div>
  )
}

/** @internal */
function _getIdFromName(name: string) {
  return name.replace(' ', '-').toLowerCase()
}
