import { QueryFunctionContext, UseMutationResult } from 'react-query'
import Api from '../../util/api'
import Organisation, { Plans } from '../../types/organisation'

export const fetchOrgs = async (
  context: QueryFunctionContext<[key?: string]>
) => {
  const response = await Api.get<{ data: Organisation[] }>('v1/organisation')

  return response?.data?.map(
    org =>
      ({
        ...org,
        plan: org?.plan?.toLocaleLowerCase() as Plans,
      } as Organisation)
  )
}

export const createOrg = async (context: CreateOrgContext) => {
  return await Api.add('v1/organisation', context.queryKey[1])
}

export const updateOrg = async (
  context: QueryFunctionContext<
    [key: string, org: Partial<Organisation> & { id: string }]
  >
) => {
  return await Api.update(
    `v1/organisation/${context.queryKey[1].id}`,
    context.queryKey[1]
  )
}

export type OrgCreator = UseMutationResult<
  unknown,
  unknown,
  CreateOrgContext,
  unknown
>

type CreateOrgContext = QueryFunctionContext<
  [key: string, org: Omit<Organisation, 'id' | 'active'>]
>
