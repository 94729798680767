import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AnalyticsContext, AnalyticsInterface } from '../..'
import { getEvent } from './http'
import Comments from './comments'
import { MessageDisplay } from './components/message-display'

type DataDisplayRowParams = {
  label: string
  value: string | number | undefined
}

const DataDisplayRow = ({ label, value }: DataDisplayRowParams) => {
  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-900">{label}</dt>
      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        {value}
      </dd>
    </div>
  )
}

export default () => {
  const { getWorkdayId } = useContext(AnalyticsContext) as AnalyticsInterface
  const { data: response } = useQuery(
    ['detail-event', getWorkdayId()],
    getEvent
  )

  return (
    <div>
      <div className="mt-6 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="flex items-center justify between">
          <div className="px-4 py-6 sm:px-6">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              Event - Detailed View
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              A detailed view of all information for this workday event.
            </p>
          </div>
          {response?.validHost && (
            <div className="ml-auto mr-10">
              <a
                href={response?.workdayHref || ''}
                className="ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                View event
              </a>
            </div>
          )}
        </div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <DataDisplayRow
              label="Integration ID"
              value={response?.integrationId}
            />
            <DataDisplayRow
              label="Integration Name"
              value={response?.integrationName}
            />
            <DataDisplayRow label="Ran By User" value={response?.ranByUser} />
            <DataDisplayRow
              label="Number of Errors"
              value={response?.numberOfErrors}
            />
            <DataDisplayRow
              label="Number of Warnings"
              value={response?.numberOfWarnings}
            />
            <DataDisplayRow
              label="Integration Status"
              value={response?.integrationStatus}
            />
            <DataDisplayRow label="Scheduled" value={response?.scheduled} />
          </dl>
        </div>
      </div>
      <MessageDisplay />
      <div className="mt-8 bg-white shadow">
        <Comments id={response?.integrationName} />
      </div>
    </div>
  )
}
