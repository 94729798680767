import { createContext } from 'react'

export enum OrganisationTabs {
  Overview = 'ORGANISATION_OVERVIEW',
  Notifications = 'ORGANISATION_NOTIFICATION',
  Tenants = 'ORGANISATION_TENANT',
  Users = 'ORGANISATION_USER',
  Emails = 'ORGANISATION_EMAIL',
}

export interface OrganisationInterface {
  setOrg: (id: string) => void
  setOrgView: (tab: OrganisationTabs) => void
  getOrg: (id: string) => any
}

export const OrganisationContext = createContext<OrganisationInterface | null>(
  null
)
