/* eslint-disable import/no-anonymous-default-export */
import { Card, Title, Text, Tab, TabList, Flex } from '@tremor/react'
import KPIDashboard from './tabs/kpis'
import { useState } from 'react'
import Integrations from './tabs/integrations'

enum DashboardTabs {
  KPI = 'DASHBOARD_KPI',
  Notification = 'DASHBOARD_NOTIFICATION',
  TenantScore = 'DASHBOARD_TENANT_SCORE',
  Integrations = 'DASHBOARD_INTEGRATIONS',
  Metrics = 'DASHBOARD_METRICS',
}

/**
 * React Component for the outline of the Dashboard page.
 *
 * @component
 */
export default (): JSX.Element => {
  const [selectedView, setSelectedView] = useState<string>(DashboardTabs.KPI)

  const renderView = () => {
    switch (selectedView) {
      case DashboardTabs.KPI:
        return (
          <Flex className="mt-6 justify-center">
            <KPIDashboard />
          </Flex>
        )

      case DashboardTabs.Integrations:
        return (
          <Flex className="mt-6">
            <Integrations />
          </Flex>
        )

      default:
        return (
          <Flex className="mt-6">
            <Card>
              <div className="h-96" />
            </Card>
          </Flex>
        )
    }
  }

  return (
    <main>
      <div className="grid grid-cols- md:grid-cols-1 place-items-start mb-6">
        <div>
          <Title>Dashboard</Title>
          <Text>
            Dashboard displays your Workday Tenant's Integration Events for a
            custom time period.
          </Text>
        </div>
      </div>

      <TabList
        defaultValue={DashboardTabs.KPI}
        onValueChange={value => setSelectedView(value)}
        className="mt-6"
      >
        <Tab value={DashboardTabs.KPI} text="KPIs" />
        <Tab value={DashboardTabs.Integrations} text="Integration Systems" />
      </TabList>

      {renderView()}
    </main>
  )
}
