import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import './index.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import ModalProvider from './layout/ModalProvider'
import Routes from './layout/routes'
import { RecoilRoot } from 'recoil'

const {
  REACT_APP_CLIENT_ID: AUTH0_CLIENT_ID,
  REACT_APP_DOMAIN: AUTH0_DOMAIN,
  REACT_APP_AUDIENCE: AUTH0_AUDIENCE,
  REACT_APP_ROOT_URL: REDIRECT_URL,
} = process.env

export function Redirect({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate()

  useEffect(() => {
    navigate({ pathname: '/dashboard' })
  }, [navigate])

  return <React.Fragment>{children}</React.Fragment>
}

function App() {
  const queryClient = new QueryClient()

  if (AUTH0_CLIENT_ID !== undefined && AUTH0_DOMAIN !== undefined) {
    return (
      <Auth0Provider
        clientId={AUTH0_CLIENT_ID}
        domain={AUTH0_DOMAIN}
        authorizationParams={{
          redirect_uri: REDIRECT_URL,
          audience: AUTH0_AUDIENCE,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <ModalProvider>
              <Routes />
            </ModalProvider>
          </RecoilRoot>
        </QueryClientProvider>
      </Auth0Provider>
    )
  }

  return <div>Invalid Auth0 Details</div>
}

export default App
