import { Title, Text, TabList, Tab } from '@tremor/react'
import { useState } from 'react'
import { OrganisationTabs } from './context'
import OverviewTab from './tabs/overview'
import TenantsTab from './tabs/overview'
import UsersTab from './tabs/users'
import NotificationsTab from './tabs/notifications'
import EmailsTab from './tabs/emails'
import { withAuthenticationRequired } from '@auth0/auth0-react'

const DetailPage = (): JSX.Element => {
  const [organisation, setOrganisation] = useState('')
  const [selectedView, setSelectedView] = useState<string>(
    OrganisationTabs.Overview
  )

  const renderTab = () => {
    switch (selectedView) {
      case OrganisationTabs.Overview:
        return <OverviewTab />

      case OrganisationTabs.Tenants:
        return <TenantsTab />

      case OrganisationTabs.Notifications:
        return <NotificationsTab />

      case OrganisationTabs.Users:
        return <UsersTab />

      case OrganisationTabs.Emails:
        return <EmailsTab />

      default:
        return <OverviewTab />
    }
  }

  return (
    <>
      <main>
        <div className="grid grid-cols- md:grid-cols-1 place-items-start mb-6">
          <div>
            <Title>Organisation</Title>
            <Text>
              An overview of organisation related resources located within
              Loopwatch
            </Text>
          </div>
        </div>

        <TabList
          defaultValue={OrganisationTabs.Overview}
          onValueChange={value => setSelectedView(value)}
        >
          <Tab value={OrganisationTabs.Overview} text="Overview" />
          <Tab value={OrganisationTabs.Notifications} text="Notifications" />
          <Tab value={OrganisationTabs.Emails} text="Emails" />
          <Tab value={OrganisationTabs.Tenants} text="Tenants" />
          <Tab value={OrganisationTabs.Users} text="Users" />
        </TabList>
        <div className="mt-6">{renderTab()}</div>
      </main>
    </>
  )
}

export default withAuthenticationRequired(DetailPage)
