import { atom } from 'recoil'

export const UserDetailsModal = atom({
  key: 'user-details', // unique ID (with respect to other atoms/selectors)
  default: {
    id: '',
    name: '',
    active: false,
    notifications: false,
    role: 'analyst',
  }, // default value (aka initial value)
})
