/* eslint-disable import/no-anonymous-default-export */
import { Card, Title, Text, Tab, TabList, Flex } from '@tremor/react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { createContext } from 'react'
import Analytics from './components/overview-tab'
import { useState } from 'react'
import EventsTab from './components/events-tab'
import DetailEvent from './components/detail-event'

export enum AnalyticsTab {
  Overview = 'ANALYTICS_OVERVIEW',
  Events = 'ANALYTICS_EVENTS',
  DetailedView = 'ANALYTICS_DETAILED_VIEW',
}

export interface AnalyticsInterface {
  getWorkdayId: () => string
  setWorkdayId: (id: string) => void
  setAnalyticsView: (tab: AnalyticsTab) => void
}

export const AnalyticsContext = createContext<AnalyticsInterface | null>(null)

/**
 * React Component for the Outline of the Analytics page.
 *
 * @component
 */
const AnalyticsOutline = (): JSX.Element => {
  const [selectedView, setSelectedView] = useState(AnalyticsTab.Overview)
  const [workdayId, setWorkdayID] = useState('')

  const renderSelectedView = () => {
    switch (selectedView) {
      case AnalyticsTab.Overview:
        return (
          <>
            <Analytics />
          </>
        )

      case AnalyticsTab.Events:
        return (
          <>
            <Card>
              <EventsTab />
            </Card>
          </>
        )

      case AnalyticsTab.DetailedView:
        return (
          <>
            <DetailEvent />
          </>
        )
    }
  }

  return (
    <AnalyticsContext.Provider
      value={{
        getWorkdayId: () => workdayId,
        setWorkdayId: (id: string) => {
          setWorkdayID(id)
        },
        setAnalyticsView: (tab: AnalyticsTab) => {
          setSelectedView(tab)
        },
      }}
    >
      <main>
        <Title>Chart Analytics</Title>
        <Text>
          A visual representation of your Workday Integration Events in a
          selected period.
        </Text>

        <TabList
          defaultValue={AnalyticsTab.Overview}
          onValueChange={value => setSelectedView(value as AnalyticsTab)}
          className="mt-6"
        >
          <Tab value={AnalyticsTab.Overview} text="Overview" />
          <Tab value={AnalyticsTab.Events} text="Integration Events" />
        </TabList>

        {renderSelectedView()}
      </main>
    </AnalyticsContext.Provider>
  )
}

export default withAuthenticationRequired(AnalyticsOutline)
