import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AnalyticsContext, AnalyticsInterface } from '../../..'
import { getEventMessages } from '../http'

type MessageRowParams = {
  id: string
  severity: string
  text: string
}

const MessageRow = ({ id, severity, text }: MessageRowParams) => {
  return (
    <li
      key={id}
      className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
    >
      <div className="flex min-w-0 gap-x-4">
        <div className="min-w-0 flex-auto">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            Severity: {severity}
          </p>
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
            Message - {text}
          </p>
        </div>
      </div>
    </li>
  )
}

export const MessageDisplay = () => {
  const { getWorkdayId } = useContext(AnalyticsContext) as AnalyticsInterface

  const { data: response } = useQuery(
    ['detail-message', getWorkdayId()],
    getEventMessages
  )

  return (
    <div className="mt-6 overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="flex items-center justify between">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Event - Message Display
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            A detailed list of all messages for this workday event.
          </p>
        </div>
      </div>
      <ul
        role="list"
        className="divide-y divide-gray-100 overflow-hidden shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
      >
        {response?.map((message: any, index: any) => (
          <MessageRow
            id={index}
            severity={message.severity}
            text={message.text}
          />
        ))}
      </ul>
    </div>
  )
}
