import { QueryFunctionContext } from 'react-query'
import Api from '../../../../util/api'

/** @internal */
type _DetailEventResponse = {
  integrationId: string
  integrationName: string
  ranByUser: string
  numberOfErrors: number
  numberOfWarnings: number
  repeatingErrors: string
  integrationStatus: string
  scheduled: string
  validHost: boolean
  workdayHref: string
}

export const createComment = async (params: any) => {
  const response = (await Api.update<any>(`v1/comment`, params)) as any
  return response.data
}

export const getEvent = async (
  context: QueryFunctionContext<[key?: string, id?: string]>
): Promise<_DetailEventResponse | undefined> => {
  const [_, id] = context.queryKey

  const response = (await Api.get<_DetailEventResponse>(
    `v1/event/${id}`
  )) as any
  // const link = await Api.get<any>(`v1/event/${id}/link`)
  return { ...response.data, href: '' } as any
}

export const getEventMessages = async (
  context: QueryFunctionContext<[key?: string, id?: string]>
): Promise<any> => {
  const [_, id] = context.queryKey

  const response = (await Api.get<any>(`v1/event/${id}/message`)) as any
  return response.data
}

export const getComments = async (
  context: QueryFunctionContext<[key?: string, id?: string]>
): Promise<any> => {
  const [_, id] = context.queryKey

  const response = (await Api.post<any>('v1/comment', {
    integration: id,
  })) as any
  return response.data
}
