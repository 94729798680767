import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
const Error_ = Error

const { REACT_APP_API_HOST } = process.env

namespace Api {
  /**
   * An Api Error.
   */
  export class Error extends Error_ {
    constructor(public status: number, message: string) {
      super(message)
    }
  }

  /**
   * Used to request data via the REST API.
   */
  export const get = async <R, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>
  ) => {
    const response = await axios.get<{ data: R }>(
      `${REACT_APP_API_HOST}/${url}`,
      config
    )

    return validate<R>(response)
  }

  /**
   * Used to get data via post from the REST API.
   */
  export const post = async <R, D = unknown>(
    url: string,
    data: D,
    config?: AxiosRequestConfig<D>
  ) => {
    const response = await axios.post<{ data: R }>(
      `${REACT_APP_API_HOST}/${url}`,
      data,
      config
    )

    return validate<R>(response)
  }

  /**
   * Used to add a data item via the REST API.
   */
  export const add = async <D extends { [key: string]: any }, R = unknown>(
    url: string,
    data: D,
    config?: AxiosRequestConfig<D>
  ) => {
    const response = await axios.post<{ data: R }>(
      `${REACT_APP_API_HOST}/${url}`,
      data,
      config
    )

    return validate(response)
  }

  /**
   * Used to remove a data item via the REST API.
   */
  export const remove = async <D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>
  ) => validate(await axios.delete(`${REACT_APP_API_HOST}/${url}`, config))

  /**
   * Used to update a data item via the REST API.
   */
  export const update = async <D = unknown>(
    url: string,
    data: Partial<D>,
    config?: AxiosRequestConfig<D>
  ) => validate(await axios.patch(`${REACT_APP_API_HOST}/${url}`, data, config))

  /**
   * Used to validate a response from the REST API.
   */
  const validate = <R = undefined>(response: AxiosResponse<{ data: R }>) => {
    if (response.status !== 200) {
      throw new Error(
        response.status,
        `Loopwatch RESTApi Responded With Error Status Code: ${response.status}; ${response.statusText}` ||
          'Unknown error'
      )
    }

    return response.data as R
  }
}

export type Error = Api.Error
export const { get, add, remove, update } = Api

export { Api }
export default Api
