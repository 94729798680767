import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Transition, Dialog } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { Card } from '@tremor/react'
import { useRef, Fragment } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { Api } from '../../util/api'

import { UserDetailsModal } from './atom'
import { fetchUsers } from './http'

const EditUserModal = () => {
  const [details, setDetails] = useRecoilState(UserDetailsModal)

  const mutation = useMutation({
    mutationFn: async (params: any) => {
      const data = await Api.update(`v1/user/${params.id}`, {
        notifications: details.notifications,
        role: details.role,
      })

      return data as any
    },
  })

  const cancelButtonRef = useRef(null)

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
  }

  function updateNotifications(value: boolean) {
    setDetails({ ...details, notifications: value })
  }

  async function updateUserDetails() {
    setDetails({ ...details, active: false })

    await mutation.mutateAsync({
      id: details.id,
      details: { notifications: details.notifications, role: details.role },
    })
  }

  return (
    <Transition.Root show={details.active} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setDetails({ ...details, active: false })}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Edit User Details: {details.name}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Update user information using the form provided below.
                        Only account administrators have the necessary
                        privileges to make changes to user details.
                      </p>
                    </div>
                    <div className="mt-2">
                      <div className="mt-5 mb-12">
                        <div className="sm:col-span-4">
                          {/*
                          <div className="mt-2 mb-4 grid grid-cols-4 text-center items-center ">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900 col-span-1"
                            >
                              Notifications
                            </label>

                            <div className="mt-2 col-span-3 justify-self-end">
                              <Switch
                                checked={details.notifications}
                                onChange={updateNotifications}
                                className={classNames(
                                  enabled ? 'bg-indigo-600' : 'bg-gray-200',
                                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                )}
                              >
                                <span className="sr-only">Use setting</span>
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    enabled ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                  )}
                                />
                              </Switch>
                            </div>
                             </div>
                            */}

                          <div className="mt-2 mb-4 grid grid-cols-4 text-center items-center">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-900 col-span-1"
                            >
                              Role
                            </label>
                            <div className="mt-2 col-span-3">
                              <input
                                type="text"
                                name="role"
                                id="role"
                                onChange={event =>
                                  setDetails({
                                    ...details,
                                    role: event.target.value,
                                  })
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={updateUserDetails}
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setDetails({ ...details, active: false })}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const Users = () => {
  const { data: response, status } = useQuery(
    ['get-users', undefined],
    fetchUsers
  )

  const [details, setDetails] = useRecoilState(UserDetailsModal)

  return (
    <div>
      <EditUserModal />
      <Card>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Users
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users in your organisaition including their
                name, title, email and role.
              </p>
            </div>
            {/*<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add user
            </button>
          </div>
        */}
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Role
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {response?.map((person: any) => (
                      <tr key={person?.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {person?.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          N/A
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person?.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person?.role}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <a
                            onClick={() =>
                              setDetails({
                                ...details,
                                active: true,
                                name: person.name,
                                id: person.id,
                              })
                            }
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Edit
                            <span className="sr-only">, {person?.name}</span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default withAuthenticationRequired(Users)
