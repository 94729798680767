import { useQuery } from 'react-query'
import { getEmailDetails } from '../detail-page/tabs/emails/http'
import { useParams } from 'react-router-dom'

export default () => {
  // get params
  let { id } = useParams()

  // init query
  const { data, status } = useQuery(
    ['get-notification-email-details', id!],
    getEmailDetails
  )

  return status !== 'success' ? (
    <span>Loading...</span>
  ) : (
    <span
      dangerouslySetInnerHTML={{
        __html:
          data?.HtmlBody ??
          data?.TextBody ??
          `Could Not Load Email with Message Id: ${id}`,
      }}
    />
  )
}
