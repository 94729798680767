/* eslint-disable import/no-anonymous-default-export */
import { useContext, Fragment, useEffect } from 'react'
import { fetchPaginatedEvents } from './http'
import usePagination from '../../../../hooks/usePagination'
import classNames from 'classnames'
import Event from '../../../../types/event'
import { AnalyticsContext, AnalyticsInterface, AnalyticsTab } from '../..'
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../../../layout/Application'
import PaginationFooter from '../../../../components/pagination-footer'
import TableFilter from '../../../../components/table-filter'

/**
 * A responsive table that displays the events that have occurred on the LoopWatch platform.
 *
 * @component
 */
export default () => {
  const { getTenantID, getDateRange, getSearchQuery, getEventLevel } =
    useContext(ApplicationContext) as ApplicationContextProps
  const tenant = getTenantID()
  const range = getDateRange()
  const query = getSearchQuery()
  const level = getEventLevel()

  const {
    status,
    data: response,
    page,
    totalPages,
    totalItems,
    incrementPage,
    decrementPage,
    setDirectPage,
    refetch,
  } = usePagination({
    fetcher: fetchPaginatedEvents,
    params: {
      tenant,
      range,
      query: query?.length === 0 ? undefined : query,
      level,
    },
    initialPage: 1,
    initialItemsPerPage: 20,
  })

  useEffect(() => {
    refetch()
  }, [tenant, range, query, level])

  return (
    <Fragment>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Recent Events
        </h2>
        <p className="mt-2 text-sm text-gray-700 mb-4">
          A comprehensive list of Integration Events that have been generated
          from your Workday Tenant in a selected time period.
        </p>
        <TableFilter />
      </div>
      <div>
        <div className="mt-6 overflow-hidden border-t border-gray-100">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <table className="w-full text-left divide-y divide-gray-300">
                <thead className="sr-only">
                  <tr>
                    <th>Amount</th>
                    <th className="hidden sm:table-cell">Client</th>
                    <th>More details</th>
                  </tr>
                </thead>
                <tbody>
                  {status !== 'loading' &&
                    response?.map((event: Event) => <_EventRow {...event} />)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <PaginationFooter
        count={20}
        page={page}
        increment={incrementPage}
        decrement={decrementPage}
        setter={setDirectPage}
        totalItems={totalItems}
        totalPages={totalPages}
      />
    </Fragment>
  )
}

/** @internal */
const _EventRow = ({
  details: { workdayId: id },
  integration: { system: name },
  event: {
    completionDate,
    status,
    level,
    numberOfErrors,
    numberOfWarnings,
    schedule,
    isRepeat,
  },
  messages: { length: messageCount } = [],
  comments: { length: commentCount } = [],
}: Event) => {
  const statusClassNames = () => {
    switch (level) {
      case 'Completed':
        return 'text-green-700 bg-green-50 ring-green-600/20 rounded-md py-1 mx-1 px-2 text-xs font-medium ring-1 ring-inset'

      case 'Completed with Warnings':
        return 'text-yellow-700 bg-yellow-50 ring-yellow-600/20 rounded-md py-1 mx-1 px-2 text-xs font-medium ring-1 ring-inset'

      case 'Completed With Errors':
        return 'text-orange-800 bg-orange-50 ring-orange-600/20 rounded-md py-1 mx-1 px-2 text-xs font-medium ring-1 ring-inset'

      case 'Failed':
        return 'text-red-700 bg-red-50 ring-red-600/20 rounded-md py-1 mx-1 px-2 text-xs font-medium ring-1 ring-inset'

      default:
        return 'text-yellow-700 bg-yellow-50 ring-yellow-600/20 rounded-md py-1 mx-1 px-2 text-xs font-medium ring-1 ring-inset'
    }
  }

  const renderedClassNames = statusClassNames()

  return (
    <tr key={id} className="even:bg-gray-50">
      <td className="relative py-5 pr-6">
        <div className="flex gap-x-6">
          <div className="flex-auto">
            <div className="flex items-start gap-x-3">
              <div className="text-lg font-medium leading-6 text-gray-900">
                {name}
              </div>
            </div>
            <div className="mt-1 text-xs flex leading-5 text-gray-500">
              {new Date(completionDate).toLocaleString()}
              <div className={renderedClassNames}>{level}</div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
        <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
        <div className="mt-2 flex items-start gap-x-3 xl:hidden">
          <div>
            <div className="text-sm leading-6 text-gray-900">Errors</div>
            <div
              className={classNames(
                !numberOfErrors ? '' : 'fond-bold',
                'mt-1 text-xs leading-5 text-gray-500'
              )}
            >
              {numberOfErrors}
            </div>
          </div>
          <div>
            <div className="text-sm leading-6 text-gray-900">Warnings</div>
            <div
              className={classNames(
                !numberOfWarnings ? '' : 'fond-bold',
                'mt-1 text-xs leading-5 text-gray-500'
              )}
            >
              {numberOfWarnings}
            </div>
          </div>
          <div>
            <div className="text-sm leading-6 text-gray-900">Messages</div>
            <div
              className={classNames(
                !messageCount ? '' : 'fond-bold',
                'mt-1 text-xs leading-5 text-gray-500'
              )}
            >
              {messageCount}
            </div>
          </div>
        </div>
        <div className="mt-1 flex justify-between lg:hidden">
          <div className="flex">
            <_EventAlerts
              wasScheduled={schedule !== null}
              isRepeat={!!isRepeat}
            />
          </div>
          <div className="justify-self-end text-right">
            <_WorkdayIntegrationButton id={id} />
          </div>
        </div>
      </td>
      <td className="hidden py-5 pr-6 xl:table-cell">
        <div className="text-sm leading-6 text-gray-900">Errors</div>
        <div
          className={classNames(
            !numberOfErrors ? '' : 'fond-bold',
            'mt-1 text-xs leading-5 text-gray-500'
          )}
        >
          {numberOfErrors}
        </div>
      </td>
      <td className="hidden py-5 pr-6 xl:table-cell">
        <div className="text-sm leading-6 text-gray-900">Warnings</div>
        <div
          className={classNames(
            !numberOfWarnings ? '' : 'fond-bold',
            'mt-1 text-xs leading-5 text-gray-500'
          )}
        >
          {numberOfWarnings}
        </div>
      </td>
      <td className="hidden py-5 pr-6 xl:table-cell">
        <div className="text-sm leading-6 text-gray-900">Messages</div>
        <div
          className={classNames(
            !messageCount ? '' : 'fond-bold',
            'mt-1 text-xs leading-5 text-gray-500'
          )}
        >
          {messageCount}
        </div>
      </td>
      <td className="hidden py-5 pr-6 lg:table-cell">
        <_EventAlerts wasScheduled={schedule !== null} isRepeat={!!isRepeat} />
      </td>
      <td className="hidden py-5 lg:table-cell text-right">
        <_WorkdayIntegrationButton id={id} />
      </td>
    </tr>
  )
}

/** @internal */
const _EventAlerts = ({
  wasScheduled,
  isRepeat,
}: {
  wasScheduled: boolean
  isRepeat: boolean
}) => (
  <Fragment>
    {wasScheduled ? (
      <div className="text-green-700 bg-green-50 ring-green-500/20 rounded-md p-2 text-xs font-medium ring-1 ring-inset items-center justify-center w-20">
        Scheduled
      </div>
    ) : (
      <div className="text-yellow-700 bg-yellow-50 ring-yellow-600/20 rounded-md py-1 pl-2 text-xs font-medium ring-1 ring-inset">
        Unscheduled
      </div>
    )}
    {isRepeat && (
      <div className="text-yellow-700 bg-yellow-50 ring-yellow-600/20 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset">
        Repeat
      </div>
    )}
  </Fragment>
)

/** @internal */
function _WorkdayIntegrationButton({ id }: { id: string }) {
  const { setWorkdayId, setAnalyticsView } = useContext(
    AnalyticsContext
  ) as AnalyticsInterface

  return (
    <div className="flex justify-end">
      <a
        onClick={() => {
          setWorkdayId(id)
          setAnalyticsView(AnalyticsTab.DetailedView)
        }}
        className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500"
      >
        View Workday Event
      </a>
    </div>
  )
}
