import { OutboundMessagesFilteringParameters } from 'postmark/dist/client/models'
import Api from '../../../../../../util/api'
import { QueryFunctionContext } from 'react-query'
import { Email, PartialEmail } from '../../../../../../types/notifications'
import { FiltersConfig } from './components/filters'

export async function getRecentEmails(
  context: QueryFunctionContext<
    [
      key: string,
      tenant: string | undefined,
      filters: FiltersConfig & OutboundMessagesFilteringParameters
    ]
  >
): Promise<PartialEmail[]> {
  const [_, tenant, filters] = context.queryKey

  if (filters.hasOwnProperty('dateRange')) {
    const [startDate, endDate] = filters.dateRange!

    filters.fromDate = startDate.toISOString()
    filters.toDate = endDate.toISOString()
  }

  const result = await Api.get<{ data: PartialEmail[] }>(
    `v1/notifications/emails/${tenant ?? ''}`,
    {
      params: filters,
    }
  )

  return result?.data ?? []
}

export async function getEmailDetails(
  context: QueryFunctionContext<[key: string, messageId: string]>
) {
  const [_, messageId] = context.queryKey

  const result = await Api.get<{ data: Email }>(
    `v1/notifications/email/${messageId}`
  )

  return result?.data
}
