import { useState } from 'react'
import OnboardingChecklist from './components/checklist'
import Tos from './components/tos'
import WqlCredentialsForm from './components/wql-credentials-form'
import { useQuery } from 'react-query'
import User, { Roles } from '../../types/user'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchUserByEmail } from '../profile/http'

const { REACT_APP_BILLING_URL: BILLING_URL } = process.env

export const BILLING_ROLES = [Roles.Admin, Roles.Billing, Roles.SuperUser]

export enum STEPS {
  TOS = 'tos',
  WORKDAY = 'wql',
  BILLING = 'billing',
  DONE = 'done',
}

export const STEP_ORDER = {
  [STEPS.TOS]: 1,
  [STEPS.WORKDAY]: 2,
  [STEPS.BILLING]: 3,
  [STEPS.DONE]: 4,
}

export const STEP_URLS = {
  LOGIN: '/login',
  [STEPS.BILLING]: BILLING_URL! + '/checkout',
  [STEPS.DONE]: '/dashboard',
}

export default ({ step: currentStep }: { step?: STEPS }): JSX.Element => {
  // init request
  const { user: auth0 } = useAuth0()

  // init state
  const [showInterstital, setShowInterstitial] = useState(true)
  const [step, setStep] = useState(currentStep ?? STEPS.TOS)
  const [user, setUser] = useState<User>({} as User)

  // init queries
  useQuery(['get-current-user', auth0?.email ?? ''], fetchUserByEmail, {
    onSuccess: (user: User) => {
      setUser({
        ...user,
      })
    },
  })

  // render
  if (showInterstital) {
    return (
      <OnboardingChecklist
        user={user}
        step={step}
        onContinue={() => setShowInterstitial(false)}
      />
    )
  } else {
    switch (step) {
      case STEPS.TOS:
        return (
          <Tos
            user={user.email}
            onContinue={() => {
              setStep(STEPS.WORKDAY)
              setShowInterstitial(true)
            }}
          />
        )
      case STEPS.WORKDAY:
        return (
          <WqlCredentialsForm
            user={user.email}
            org={user.org}
            onContinue={() => {
              setStep(STEPS.BILLING)
              setShowInterstitial(true)
            }}
          />
        )
      case STEPS.BILLING:
        window.location.href = STEP_URLS[STEPS.BILLING]
        return <div>Redirecting...</div>
      case STEPS.DONE:
      default:
        window.location.href = STEP_URLS[STEPS.DONE]
        return <div>Redirecting...</div>
    }
  }
}
