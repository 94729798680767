import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { User } from '@auth0/auth0-react'
import { classNames } from '../../../../util/styles'

/**
 * The profile dropdown menu for the large stacked layout's nav header.
 *
 * @component
 */
export default ({
  user,
  userNavigation,
}: {
  user: User | undefined
  userNavigation: { name: string; onClick: () => void }[]
}) => {
  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <span className="sr-only">Open user menu</span>
          <img className="h-8 w-8 rounded-full" src={user?.picture} alt="" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map(item => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <a
                  href={'#'}
                  onClick={item.onClick}
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )}
                >
                  {item.name}
                </a>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
