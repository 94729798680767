import Api from '../../../../util/api'
import { QueryFunctionContext } from 'react-query'

const queryGraph = async (
  context: QueryFunctionContext<
    [
      key: string,
      type: string,
      period: string | undefined,
      tenant: string | undefined,
      range?: any
    ]
  >
) => {
  const { queryKey } = context
  const [_, type, period, tenant, range] = queryKey

  const data = await Api.post<{ data: any[]; count: number }>('v1/graph', {
    type,
    period,
    tenant,
    range,
  })

  return data
}

export default {
  queryGraph,
}
