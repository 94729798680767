import { XCircleIcon } from '@heroicons/react/24/outline'
import { Flex, Card, Icon } from '@tremor/react'
import { User, useAuth0 } from '@auth0/auth0-react'
import { logOut } from '../http'

export default ({
  children,
  email,
}: {
  children: React.ReactNode
  email: string | undefined
}) => {
  const { logout } = useAuth0()

  return (
    <Flex justifyContent="center" className="w-full my-6">
      <Card className="bg-gray-200 w-11/12 md:w-3/4 mt-4 pt-4">
        <Flex flexDirection="col" justifyContent="center">
          <Card className="mt-3">
            <Flex alignItems="start">
              <div>
                <h1 className="text-2xl font-bold whitespace-nowrap">
                  Welcome To Loopwatch
                </h1>
                {email !== undefined && (
                  <p className="whitespace-nowrap mt-1 ml-2 text-sm">
                    Onboarding For: <b>{email}</b>
                  </p>
                )}
              </div>
              {email !== undefined && (
                <Flex justifyContent="end" alignItems="center">
                  <button
                    onClick={() => logOut(logout)}
                    className="hover:outline outline-offset-2 outline-red-500 rounded-lg text-sm dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
                  >
                    <Flex
                      justifyContent="end"
                      alignItems="center"
                      className="pl-2"
                      title="You will be asked to continue and complete your Onboarding the next time you log-in."
                    >
                      Log Out <Icon icon={XCircleIcon} color="red" />
                    </Flex>
                  </button>
                </Flex>
              )}
            </Flex>
          </Card>
          <Card className="mt-6">{children}</Card>
        </Flex>
      </Card>
    </Flex>
  )
}
