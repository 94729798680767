import { createContext, useReducer } from 'react'

export type ModalContextType = {
  clearValues: () => void
  getModalValue: (modal: EModal) => any
  getModalStatus: (modal: EModal) => boolean
  assignModalValue: (modal: EModal, value: any) => void
  activateModal: (modal: EModal) => any
}

export enum EModal {
  CreateUser = 'CREATE_USER',
  Clear = 'CLEAR',
}

export const ModalContext = createContext<ModalContextType>({
  clearValues: () => {},
  getModalStatus: (modal: EModal) => false,
  getModalValue: (modal: EModal) => false,
  assignModalValue: (modal: EModal, value: any) => {},
  activateModal: (modal: EModal) => {},
})

enum EModalAction {
  UpdateModalState = 'UPDATE_MODAL_STATE',
  ActivateModalState = 'ACTIVATE_MODAL_STATE',
  ClearModalState = 'CLEAR_MODAL_STATE',
}

const initialPayload = {
  value: {
    [EModal.CreateUser]: {
      value: null,
    },
  },
  active: {
    [EModal.CreateUser]: false,
  },
}

type ModalState = {
  value?: {
    [EModal.CreateUser]: {
      value: any | null
    }
  }
  active?: {
    [EModal.CreateUser]: boolean
  }
}

type ActionType = {
  type: EModalAction
  modal?: EModal
  value?: any
}

const ModalReducer = (state: ModalState, action: ActionType): any => {
  switch (action.type) {
    case EModalAction.UpdateModalState: {
      const modal = action.modal as EModal
      return {
        ...state,
        value: {
          ...state.value,
          [modal]: { value: action.value },
        },
      }
    }

    case EModalAction.ActivateModalState: {
      const modal = action.modal as EModal
      return {
        ...state,
        active: {
          ...state.active,
          [modal]: true,
        },
      }
    }

    case EModalAction.ClearModalState:
      return { ...state, ...initialPayload }

    default:
      return state
  }
}

const ModalProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(ModalReducer, initialPayload)

  const clearValues = () => {
    dispatch({ type: EModalAction.ClearModalState })
  }

  function getModalValue(modal: EModal) {
    const previousState = state as ModalState
    const previousValue = previousState.value as any

    if (typeof previousValue !== 'undefined') {
      return previousValue[modal].value
    }

    return undefined
  }

  const assignModalValue = (modal: EModal, value: any) => {
    switch (modal) {
      case EModal.CreateUser:
        dispatch({
          type: EModalAction.UpdateModalState,
          modal,
          value,
        })
        break

      default:
        break
    }
  }

  const getModalStatus = (modal: EModal) => {
    switch (modal) {
      case EModal.CreateUser:
        const previousState = state as ModalState
        const previousValue = previousState.active

        if (typeof previousValue !== 'undefined') return previousValue[modal]

        return false

      default:
        return false
    }
  }

  const activateModal = (modal: EModal) => {
    switch (modal) {
      case EModal.CreateUser:
        dispatch({
          type: EModalAction.ActivateModalState,
          modal,
        })
        break

      default:
        break
    }
  }

  return (
    <ModalContext.Provider
      value={{
        assignModalValue,
        activateModal,
        getModalValue,
        clearValues,
        getModalStatus,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
