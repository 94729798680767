import OnboardingCard from './card'
import { redirect } from 'react-router-dom'
import { Flex, Icon } from '@tremor/react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { BILLING_ROLES, STEPS, STEP_ORDER } from '../index'
import User, { Roles } from '../../../types/user'

/**
 * Onboarding Checklist/Interstitial Page
 *
 * @component
 */
const OnboardingChecklist = ({
  user,
  step,
  onContinue,
}: {
  user: User
  step: STEPS
  onContinue: () => void
}) => {
  const hasBillingRole = user.roles?.some(r => BILLING_ROLES.includes(r))

  const waitingFor =
    step === STEPS.BILLING && !hasBillingRole
      ? `Only Users with one of the roles: '${BILLING_ROLES.join(
          ', '
        )}' within their Organization may update the Payment Plan and Billing settings. Please contact one of your Organization's Billing Administrators for further assistance.`
      : undefined

  // render
  return (
    <OnboardingCard email={user.email}>
      <Flex flexDirection="col" alignItems="start">
        {/* Title */}
        <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">
          Onboarding Steps:
        </h3>

        {/* Checklist */}
        <ul className="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white w-full">
          <_Step
            name="Accept the Terms of Service"
            isComplete={STEP_ORDER[step] > STEP_ORDER[STEPS.TOS]}
            isCurrent={step === STEPS.TOS}
            onClick={onContinue}
          />
          <_Step
            name="Provide your Workday Autorization Credentials"
            isComplete={STEP_ORDER[step] > STEP_ORDER[STEPS.WORKDAY]}
            isCurrent={step === STEPS.WORKDAY}
            onClick={onContinue}
          />
          <_Step
            name="Select a Payment Plan"
            isComplete={STEP_ORDER[step] > STEP_ORDER[STEPS.BILLING]}
            isEnabled={hasBillingRole}
            isCurrent={step === STEPS.BILLING}
            onClick={onContinue}
            disabledMessage="You must be a Billing Admin for your Organization to select a Payment Plan."
          />
        </ul>

        {/* Waiting Warning */}
        {!!waitingFor && (
          <p className="mt-4 text-sm text-gray-900 dark:text-white">
            {waitingFor}
          </p>
        )}

        {/* Continue Button */}
        <Flex className="w-full" alignItems="center" justifyContent="center">
          <_ContinueButton
            onClick={onContinue}
            step={step}
            isEnabled={step !== STEPS.BILLING || hasBillingRole}
          />
        </Flex>
      </Flex>
    </OnboardingCard>
  )
}

export default OnboardingChecklist

/** @internal */
const _ContinueButton = ({
  isEnabled,
  onClick,
  step,
}: {
  isEnabled: boolean
  onClick: () => void
  step: STEPS
}) => {
  return (
    <button
      onClick={onClick}
      className={
        isEnabled
          ? 'px-4 py-2 mt-4 text-sm text-center font-medium text-white transition duration-150 ease-in-out bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-700 dark:hover:bg-blue-800 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700'
          : 'px-4 py-2 mt-4 text-center text-sm font-medium text-white transition duration-150 ease-in-out bg-blue-600 border border-transparent rounded-md shadow-sm opacity-50'
      }
      disabled={!isEnabled}
    >
      {isEnabled ? (
        <>
          Continue{' '}
          {step === STEPS.DONE
            ? 'to Loopwatch!'
            : 'to the next Onboarding Step'}
        </>
      ) : (
        <>Unable To Continue</>
      )}
    </button>
  )
}

/** @internal */
const _Step = ({
  name,
  isComplete,
  isCurrent,
  isEnabled = true,
  disabledMessage,
  onClick,
}: {
  name: string
  isComplete: boolean
  isCurrent?: boolean
  isEnabled?: boolean
  disabledMessage?: string
  onClick: () => void
}) => (
  <li
    className={
      'w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600' +
      (!isEnabled ? ' bg-red-100' : '') +
      (!isCurrent ? ' opacity-50' : ' underline hover:cursor-pointer')
    }
    onClick={isCurrent && isEnabled ? onClick : () => {}}
  >
    <Flex
      className="flex items-center pl-3"
      title={!isEnabled ? disabledMessage : undefined}
    >
      <Flex justifyContent="start">
        <_StepCheckbox
          name={name}
          isComplete={isComplete}
          isEnabled={isEnabled}
          disabledMessage={disabledMessage}
        />
        <_Label name={name} isComplete={isComplete} />
        {isEnabled ? <></> : <Icon icon={ExclamationCircleIcon} color="red" />}
      </Flex>
    </Flex>
  </li>
)

/** @internal */
const _Label = ({
  name,
  isComplete,
}: {
  name: string
  isComplete: boolean
}) => (
  <label
    htmlFor={name}
    className={
      'py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300' +
      (isComplete ? ' line-through' : '')
    }
  >
    {name}
  </label>
)

/** @internal */
const _StepCheckbox = ({
  name,
  isComplete,
  isEnabled,
  disabledMessage,
  link,
}: {
  name: string
  isComplete: boolean
  isEnabled: boolean
  disabledMessage: string | undefined
  link?: string
}) => (
  <input
    id={name}
    type="checkbox"
    checked={isComplete}
    onClick={() => (isEnabled ? redirect(link!) : undefined)}
    className={
      'w-4 h-4 text-blue-600 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500' +
      (isEnabled
        ? 'bg-gray-200 border-gray-500'
        : 'bg-gray-100 border-gray-300')
    }
    disabled={!isEnabled}
    title={!isEnabled ? disabledMessage : undefined}
    readOnly
  />
)
