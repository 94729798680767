import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PartialEmail } from '../../../../../../../types/notifications'
import { FilterKeys, FiltersConfig } from './filters'
import { DateRangePicker, SelectBox, SelectBoxItem } from '@tremor/react'
import React from 'react'

/**
 * Component for the email notifications filters modal.
 *
 * @component
 */
export default function ({
  isOpen,
  close,
  types,
  currentEmails,
  currentFilters,
  onFiltersChanged,
}: {
  isOpen: boolean
  close: () => void
  types: readonly FilterKeys[]
  currentFilters: FiltersConfig
  currentEmails: PartialEmail[]
  onFiltersChanged: (newFilters: FiltersConfig) => void
}) {
  // init consts
  const cancelButtonRef = useRef(null)

  // init state
  const [filterKey, setFilterKey] = React.useState<FilterKeys | undefined>(
    types[0]
  )
  const [filterValue, setFilterValue] = React.useState<string | undefined>()
  const [filterDates, setFilterDates] = React.useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined])

  // compose jsx
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      {/* Modal Container */}
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Modal Contents */}
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    {/* Modal Title */}
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 border-b border-gray-900/10 pb-2"
                    >
                      Set Filter
                    </Dialog.Title>
                    <div className="text-left border-b border-gray-900/10 pb-2">
                      {/* Modal Body */}
                      <div className="mt-2">
                        <div className="flex">
                          <SelectBox
                            value={filterKey}
                            onValueChange={e => (
                              setFilterKey(e as FilterKeys),
                              setFilterValue(undefined)
                            )}
                          >
                            {types.map(type => {
                              const spaced = type.replace(/([A-Z])/g, ' $1')
                              const fieldText =
                                (spaced?.charAt(0).toUpperCase() ?? '') +
                                (spaced?.slice(1) ?? '')

                              return (
                                <SelectBoxItem value={type} text={fieldText} />
                              )
                            })}
                          </SelectBox>
                          <_FilterOptions
                            currentFilterDates={filterDates}
                            setFilterDates={setFilterDates}
                            currentFilterValue={filterValue ?? ''}
                            setCurrentFilterValue={setFilterValue}
                            currentEmails={currentEmails}
                            currentFilterKey={filterKey}
                          />
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal Footer */}
                <_ModalButtons
                  close={close}
                  cancelButtonRef={cancelButtonRef}
                  currentFilterValue={filterValue}
                  currentFilterDates={filterDates}
                  onApply={() =>
                    onFiltersChanged({
                      ...currentFilters,
                      filterCount: currentFilters.filterCount + 1,
                      [filterKey as FilterKeys]:
                        filterKey === 'dateRange' ? filterDates : filterValue,
                    })
                  }
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

/** @internal */
const _ModalButtons = ({
  close,
  cancelButtonRef,
  currentFilterValue,
  currentFilterDates,
  onApply,
}: {
  close: () => void
  currentFilterValue?: string
  currentFilterDates: [Date | undefined, Date | undefined]
  cancelButtonRef: React.LegacyRef<any>
  onApply: () => void
}): JSX.Element => {
  const disabled =
    (currentFilterValue === undefined || currentFilterValue === '') &&
    (currentFilterDates[0] === undefined || currentFilterDates[1] === undefined)

  return (
    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
      <button
        type="submit"
        className={
          'inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2' +
          (disabled ? ' opacity-50 cursor-not-allowed' : '')
        }
        onClick={onApply}
        disabled={disabled}
      >
        Apply
      </button>
      <button
        type="button"
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
        onClick={close}
        ref={cancelButtonRef}
      >
        Cancel
      </button>
    </div>
  )
}

const _FilterOptions = ({
  currentEmails,
  currentFilterKey,
  currentFilterValue,
  currentFilterDates,
  setFilterDates,
  setCurrentFilterValue,
}: {
  currentEmails: PartialEmail[]
  currentFilterKey: FilterKeys | undefined
  currentFilterValue: string
  currentFilterDates: [Date | undefined, Date | undefined]
  setFilterDates: (newDates: [Date | undefined, Date | undefined]) => void
  setCurrentFilterValue: (newValue: string) => void
}) => {
  switch (currentFilterKey) {
    case 'subject':
    case 'tag':
      return (
        <_Select
          value={currentFilterValue}
          onChange={setCurrentFilterValue}
          values={currentEmails.map(
            email =>
              email[
                (currentFilterKey[0].toUpperCase() +
                  currentFilterKey.slice(1)) as 'Subject' | 'Tag'
              ]
          )}
        />
      )

    case 'dateRange':
      return (
        <DateRangePicker
          value={currentFilterDates}
          onValueChange={v =>
            setFilterDates([v[0] ?? undefined, v[1] ?? undefined])
          }
        />
      )

    case 'recipient':
      return (
        <_Select
          value={currentFilterValue}
          onChange={setCurrentFilterValue}
          values={currentEmails
            .map(email => email.To)
            .flat()
            .map(to => to.Email)}
        />
      )

    case 'status':
      return (
        <SelectBox
          value={currentFilterValue}
          onValueChange={setCurrentFilterValue}
        >
          {['Sent', 'Processed', 'Queued'].map(value => (
            <SelectBoxItem value={value}>{value}</SelectBoxItem>
          ))}
        </SelectBox>
      )

    default:
      return <input type="text" placeholder="Value" />
  }
}

const _Select = ({
  value,
  values,
  onChange,
}: {
  value: string | undefined
  values: (string | undefined)[]
  onChange: (newValue: string) => void
}) => {
  return (
    <SelectBox value={value} onValueChange={onChange}>
      {values
        .filter(value => value !== undefined)
        .map(value => {
          const fieldText =
            (value?.charAt(0).toUpperCase() ?? '') + (value?.slice(1) ?? '')

          return <SelectBoxItem value={value ?? ''} text={fieldText} />
        })}
    </SelectBox>
  )
}
