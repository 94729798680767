/* eslint-disable import/no-anonymous-default-export */
import Divider from '../../../../components/tabs/divider'
import EventResolution from './components/event-resolution'
import KPIStatistics from './components/kpi-statistics'
import { Level } from '../../types'
import { useQuery } from 'react-query'
import { fetchIntegrations } from '../../http'
import { useContext } from 'react'
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../../../layout/Application'
import { EventLevel } from '../../../../types/event'
import LoadingSpinner from '../../../../components/loading-spinner'

export default () => {
  const { getTenantID, getDateRange } = useContext(
    ApplicationContext
  ) as ApplicationContextProps

  const { data: completed_data, status: completed_status } = useQuery(
    [
      'get-integrations',
      'PERIOD_MONTHLY',
      EventLevel.Completed,
      getTenantID(),
      getDateRange(),
    ],
    fetchIntegrations
  )

  const { data: warnings_data, status: warning_status } = useQuery(
    [
      'get-integrations',
      'PERIOD_MONTHLY',
      EventLevel.CompletedWithWarnings,
      getTenantID(),
      getDateRange(),
    ],
    fetchIntegrations
  )

  const { data: error_data, status: error_status } = useQuery(
    [
      'get-integrations',
      'PERIOD_MONTHLY',
      EventLevel.CompletedWithErrors,
      getTenantID(),
      getDateRange(),
    ],
    fetchIntegrations
  )

  const { data: failed_data, status: failed_status } = useQuery(
    [
      'get-integrations',
      'PERIOD_MONTHLY',
      EventLevel.Failed,
      getTenantID(),
      getDateRange(),
    ],
    fetchIntegrations
  )

  const Dashboard = () => {
    return (
      <div className="mx-auto">
        <div className="mt-4 mb-4">
          <KPIStatistics />
        </div>

        <div className="mt-6 mb-6">
          <Divider />
          <h3 className="text-sm text-gray-500 mt-3 mb-2">
            Integration Event Totals and Results by Status for the 10 most
            frequent Systems:
          </h3>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {completed_status === 'success' && (
            <EventResolution
              level={Level.Completed}
              integrations={completed_data}
            />
          )}
          {warning_status === 'success' && (
            <EventResolution
              level={Level.CompletedWithWarnings}
              integrations={warnings_data}
            />
          )}
        </div>
        <div className="mt-6 mb-6">
          <Divider />
        </div>
        <div className="grid grid-cols-2 gap-4">
          {error_status === 'success' && (
            <EventResolution
              level={Level.CompletedWithErrors}
              integrations={error_data}
            />
          )}
          {failed_status === 'success' && (
            <EventResolution level={Level.Failed} integrations={failed_data} />
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      {[completed_status, warning_status].some(item => item === 'loading') ? (
        <LoadingSpinner showBranding={false} />
      ) : (
        <Dashboard />
      )}
    </div>
  )
}
