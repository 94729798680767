/* eslint-disable import/no-anonymous-default-export */
import { Fragment } from 'react'
import EventsTable from './table'

/**
 *  React Component for the content of the Events Tab of the Analytics page
 *
 *  @component
 */
export default (): JSX.Element => (
  <Fragment>
    <EventsTable />
  </Fragment>
)
