import { createRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { signInUser } from '../util/auth'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'

interface Props {}

const SignIn: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { loginWithRedirect } = useAuth0()

  const nameRef = createRef<HTMLInputElement>()
  const passwordRef = createRef<HTMLInputElement>()

  const signInWithPassword = useCallback(async () => {
    await loginWithRedirect({ appState: { targetUrl: '/dashboard' } })
  }, [nameRef, navigate, passwordRef])

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Loopwatch
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <button
                  type="submit"
                  onClick={signInWithPassword}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignIn
