import { QueryFunctionContext } from 'react-query'
import Api from '../util/api'
import Tenant from '../types/tenant'
import User from '../types/user'
import { STEPS } from '../pages/onboarding'

export const fetchTenants = async (
  context: QueryFunctionContext<[key?: string]>
) => {
  return (await Api.get<{ data?: Tenant[] }>('v1/tenant'))?.data
}

export const fetchUser = async (
  context: QueryFunctionContext<[key?: string]>
) => {
  return await Api.get<User>('v1/identity/user')
}

export const fetchCurrentOnboardingStep = async (
  context: QueryFunctionContext<
    [key: string, logoutAndLogin: () => Promise<void>]
  >
) => {
  try {
    const response = await Api.get<{ step?: STEPS }>('v1/onboarding/step')

    return (response as any)?.step
  } catch (error) {
    await context.queryKey[1]()
  }
}
