import { QueryFunctionContext } from 'react-query'
import Api from '../../../../util/api'
import Integration, { IntegrationDTO } from '../../../../types/integration'
import { EventLevel } from '../../../../types/event'

export const fetchPaginatedIntegrations = async (
  page: number,
  count: number,
  params: any
): Promise<any> => {
  const { tenant, range, level, order } = params
  const result = await Api.get<{ data: IntegrationDTO[] }>('v1/integrations', {
    params: {
      page,
      limit: count,
      tenant,
      range,
      level,
      order
    },
  })

  return {
    ...result,
    data: result?.data?.map(integration => ({
      ...integration,
      numberOfEvents: integration.count,
      name: integration.system,
    })),
  }
}

export const fetchIntegrations = async (
  context: QueryFunctionContext<
    [
      key?: string,
      tenant?: string,
      limit?: number,
      level?: EventLevel,
      range?: any | undefined
    ]
  >
): Promise<Integration[]> => {
  const [, tenant, limit = 20, level, range] = context.queryKey
  const result = await Api.get<{ data: IntegrationDTO[] }>('v1/integrations', {
    params: {
      tenant,
      limit,
      level,
      range,
    },
  })

  return result?.data?.map(integration => ({
    ...integration,
    numberOfEvents: integration.count,
    name: integration.system,
  }))
}
