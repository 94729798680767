import React from 'react'
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableBody,
  Button,
} from '@tremor/react'
import './selection-table.css'

//#region components

/**
 * React Component for a sortable table with selectable rows.
 *
 * @component
 */
class SelectionTable<
  TColumns extends Columns<TKeys, TNames>,
  TKeys extends string = TColumns extends Columns<infer K, infer _> ? K : never,
  TNames extends string = TColumns extends Columns<TKeys, infer N> ? N : never
> extends React.Component<Props<TColumns, TKeys, TNames>> {
  state = {
    selectAllEnabled: false,
    sortByColumnIndex: 0,
    sortAscending: true,
    selectedRows: new Set<string>(),
  }

  /**
   * The currently selected rows of the table.
   */
  get selectedRows() {
    return [...this.state.selectedRows]
  }

  constructor(props: Props<TColumns, TKeys, TNames>) {
    super(props)
  }

  #toggleSortAscending = () =>
    this.setState({ sortAscending: !this.state.sortAscending })

  #setSortByColumnIndex = (index: number) =>
    this.setState({ sortByColumnIndex: index })

  #toggleSelectedRow(rowId: string) {
    const selectedRows = new Set(this.state.selectedRows)
    let selectAllEnabled = this.state.selectAllEnabled

    if (selectedRows.has(rowId)) {
      selectedRows.delete(rowId)
      selectAllEnabled = false
    } else {
      selectedRows.add(rowId)
    }

    this.setState({ selectedRows, selectAllEnabled })
  }

  #toggleAllRowsSelected() {
    const selectAllEnabled = !this.state.selectAllEnabled

    const selectedRows = selectAllEnabled
      ? new Set(this.props.rows.map(row => this.props.getRowId(row)))
      : new Set()

    this.setState({ selectAllEnabled, selectedRows })
  }

  render() {
    // sort rows
    const sortedRows = this.props.rows
      .map(row => ({ ...row }))
      .sort((a, b) => {
        const columnToSortBy: TColumns[number] =
          this.props.columns[this.state.sortByColumnIndex]
        const propertyKey: TKeys = columnToSortBy.key
        const aProperty = String(a[propertyKey])
        const bProperty = String(b[propertyKey])

        if (this.state.sortAscending) {
          return aProperty.localeCompare(bProperty, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        } else {
          return bProperty.localeCompare(aProperty, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        }
      })

    // render table
    return (
      <div className="selection-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell className="text-left" key={'isSelected'}>
                <_Checkbox
                  isChecked={this.state.selectAllEnabled}
                  onChange={() => this.#toggleAllRowsSelected()}
                />
              </TableHeaderCell>
              {this.props.columns.map((column, index) => (
                <TableHeaderCell key={column.key}>
                  <Button
                    variant="light"
                    onClick={() =>
                      index === this.state.sortByColumnIndex
                        ? this.#toggleSortAscending()
                        : this.#setSortByColumnIndex(index)
                    }
                  >
                    {index === this.state.sortByColumnIndex
                      ? (this.state.sortAscending ? '▲' : '▼') +
                        ' ' +
                        column.name
                      : column.name}
                  </Button>
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map((row, index: number) => (
              <TableRow key={this.props.getRowId(row)}>
                <TableCell key={'isSelected'}>
                  <_Checkbox
                    isChecked={this.state.selectedRows.has(
                      this.props.getRowId(row)
                    )}
                    onChange={() =>
                      this.#toggleSelectedRow(this.props.getRowId(row))
                    }
                  />
                </TableCell>
                {this.props.columns.map((column: TColumns[number]) => (
                  <TableCell key={column.key}>
                    {column.transform?.(row[column['key']], index) ??
                      row[column['key']]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default SelectionTable

/** @internal */
const _Checkbox = (props: {
  isChecked?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}) => (
  <div>
    <input
      aria-describedby="offers-description"
      name="offers"
      type="checkbox"
      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
      style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'gray' }}
      checked={props.isChecked}
      onChange={props.onChange}
    />
  </div>
)

//#endregion

//#region types

/**
 * All Columns for a SelectionTable component.
 */
export type Column<TKey extends string, TName extends string> = {
  name: TName
  key: TKey
  transform?: (value: any, rowNumber: number) => React.ReactNode
}

/**
 * Column data for the SelectionTable component.
 */
export type Columns<
  TKeys extends string,
  TNames extends string
> = readonly Column<TKeys, TNames>[]

/**
 * Row data for the SelectionTable component.
 */
export type Row<
  TColumns extends Columns<TKeys, TNames>,
  TKeys extends string = TColumns extends Columns<infer K, infer _> ? K : never,
  TNames extends string = TColumns extends Columns<TKeys, infer N> ? N : never
> = {
  [key in TColumns[number]['key']]:
    | string
    | number
    | boolean
    | Array<string | number | boolean>
}

/**
 * Props for the SelectionTable component.
 */
export type Props<
  TColumns extends Columns<TKeys, TNames>,
  TKeys extends string = TColumns extends Columns<infer K, infer _> ? K : never,
  TNames extends string = TColumns extends Columns<TKeys, infer N> ? N : never
> = {
  columns: TColumns
  rows: Row<TColumns, TKeys, TNames>[]
  getRowId(row: Row<TColumns, TKeys, TNames>): string
}

//#endregion
