import { Link, NavLink } from 'react-router-dom'
import { User } from '@auth0/auth0-react'
import StackedLayoutHeaderFilters from './filters'
import ProfileDropdownMenu from './profile-dropdown-menu'
import Logo from '../../../../components/branding/logo'
import { classNames } from '../../../../util/styles'

/**
 * The navigation header-bar contents for the large stacked layout.
 *
 * @component
 */
export default ({
  navigation,
  onTenantChange,
  user,
  userNavigation,
}: {
  navigation: { name: string; href: string; current: boolean }[]
  onTenantChange: (value: any) => void
  user: User | undefined
  userNavigation: { name: string; onClick: () => void }[]
}) => {
  return (
    <>
      <div className="flex">
        <div className="flex flex-shrink-0 items-center">
          <Link to="/">
            <Logo size="sm" />
          </Link>
        </div>
        <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
          {navigation.map(item => (
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? 'border-indigo-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                )
              }
              aria-current={item.current ? 'page' : undefined}
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
      <div className="hidden sm:ml-6 sm:flex sm:items-center">
        <StackedLayoutHeaderFilters onTenantChange={onTenantChange} />
        {/**
       *   <button
        type="button"
        className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <span className="sr-only">View notifications</span>
        <BellIcon className="h-6 w-6" aria-hidden="true" />
      </button>
       */}
        <ProfileDropdownMenu user={user} userNavigation={userNavigation} />
      </div>
    </>
  )
}
