/* eslint-disable import/no-anonymous-default-export */
import {
  Bold,
  Callout,
  Card,
  List,
  ListItem,
  Metric,
  ProgressBar,
  Text,
} from '@tremor/react'

import { ArrowTrendingUpIcon } from '@heroicons/react/24/solid'
import { Level } from '../../../types'

const renderTitle = (level: Level) => {
  switch (level) {
    case Level.Completed:
      return 'Completed Events'

    case Level.CompletedWithWarnings:
      return 'Completed with Warnings Events'

    case Level.CompletedWithErrors:
      return 'Completed with Errors Events'

    case Level.Failed:
      return 'Failed Events'
  }
}

export default ({
  level,
  integrations,
}: {
  level: Level
  integrations: any
}) => {
  return (
    <Card>
      <Text> {renderTitle(level)} </Text>
      <div className="start baseline space-x-1">
        <Metric> {integrations?.sum} </Metric>
      </div>
      {integrations?.data?.forecast && (
        <>
          <Callout
            title="Resolving 6x more issues than receiving"
            icon={ArrowTrendingUpIcon}
            color="emerald"
            className="mt-6"
          >
            45 days until all issues are solved with the current cadence and
            income rate. Keep going, you got this!
          </Callout>
          <ProgressBar percentageValue={75} color="emerald" className="mt-6" />
        </>
      )}
      <div className="mt-6">
        <Text>
          <Bold>Top 10 Integration Systems by Event Run Count</Bold>
        </Text>
      </div>
      <List className="mt-1">
        {integrations?.occurences.map((item: any) => (
          <ListItem key={item.issue}>
            <span> {item.key} </span>
            <span> {item.value} </span>
          </ListItem>
        ))}
      </List>
    </Card>
  )
}
