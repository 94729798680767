import { useState } from 'react'
import { useQuery } from 'react-query'

type PaginationInput = {
  fetcher: (page: number, count: number, params?: any) => Promise<any>
  initialPage?: number
  initialItemsPerPage?: number
  params?: any
  query?: any
}

type PaginationResponse = {
  status: string
  refetch: (params?: any) => void
  data: any[]
  isLoading: boolean
  isError: boolean
  error: any
  page: number
  totalPages: number
  itemsPerPage: number
  totalItems: number
  setDirectPage: (page: number) => void
  incrementPage: () => void
  decrementPage: () => void
}

const usePagination = ({
  fetcher,
  params,
  initialPage = 1,
  initialItemsPerPage = 10,
}: PaginationInput): PaginationResponse => {
  const [page, setPage] = useState(initialPage)
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage)

  const {
    status,
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(
    ['items', page, itemsPerPage],
    () => fetcher(page, itemsPerPage, params),
    { keepPreviousData: true }
  )

  const totalItems = response?.total || 0 // Assuming the fetched data contains a total count
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const setDirectPage = (page: number) => {
    setPage(page)
    refetch()
  }

  const incrementPage = () => {
    setPage(prevPage => Math.min(prevPage + 1, totalPages))
    refetch()
  }

  const decrementPage = () => {
    setPage(prevPage => Math.max(prevPage - 1, 1))
    refetch()
  }

  return {
    status,
    refetch,
    data: response?.data || [],
    isLoading,
    isError,
    error,
    page,
    totalPages,
    itemsPerPage,
    totalItems,
    setDirectPage,
    incrementPage,
    decrementPage,
  }
}

export default usePagination
