import { ChevronRightIcon } from '@heroicons/react/20/solid'
import usePagination from '../../hooks/usePagination'

type PaginationProps = {
  count: number
  page: number
  totalPages: number
  totalItems: number
  increment: () => void
  decrement: () => void
  setter?: (page: number) => void
}

export default (props: PaginationProps) => {
  const { page, totalPages, totalItems, increment, decrement, setter } = props
  const links = []

  

  for (
    let i = Math.max(1, page - 2);
    i <= Math.min(totalPages, page + 2);
    i++
  ) {
    links.push(
      <p
        key={i}
        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
          i === page
            ? 'bg-indigo-600 text-white'
            : 'text-gray-900 hover:bg-gray-50'
        } ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0`}
        onClick={() => setter && setter(i)}
      >
        {i}
      </p>
    )
  }

  // Add links for the first and last pages
  if (page > 3) {
    links.unshift(
      <p
        key="first"
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        onClick={() => increment()}
      >
        1
      </p>
    )
  }

  if (page < totalPages - 2) {
    links.push(
      <p
        key="last"
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
      >
        {totalPages}
      </p>
    )
  }

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-1 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          href="#"
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => decrement()}
        >
          Previous
        </a>
        <a
          href="#"
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => increment()}
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{page}</span> to{' '}
            <span className="font-medium">{totalPages}</span> of{' '}
            <span className="font-medium">{totalItems}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {links.map(link => link)}
            <p
              onClick={() => increment()}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </p>
          </nav>
        </div>
      </div>
    </div>
  )
}
