import { Disclosure } from '@headlessui/react'
import { User } from '@auth0/auth0-react'
import NavPanel from './small-nav-panel'
import { NavPannelButton } from './small-nav-toggle'
import NavBar from './large-nav-bar'

export function Header({
  navigation,
  onTenantChange,
  user,
  userNavigation,
}: {
  navigation: { name: string; href: string; current: boolean }[]
  onTenantChange: (value: any) => void
  user: User | undefined
  userNavigation: { name: string; onClick: () => void }[]
}) {
  return (
    <Disclosure as="nav" className="border-b border-gray-200 bg-white">
      {({ open }) => (
        <>
          {/* Nav Bar */}
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              {/* Large Layout Nav Bar Header */}
              <NavBar
                navigation={navigation}
                onTenantChange={onTenantChange}
                user={user}
                userNavigation={userNavigation}
              />
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Open Small Layout Nav Panel Button */}
                <NavPannelButton open={open} />
              </div>
            </div>
          </div>

          {/* Small Layout Nav Panel */}
          <NavPanel
            user={user}
            navigation={navigation}
            userNavigation={userNavigation}
          />
        </>
      )}
    </Disclosure>
  )
}
