import { createContext, useMemo, useState } from 'react'
import StackedLayout from '../stacked'

export interface ApplicationContextProps {
  modifyTenant: (value: boolean) => void
  showSelector: () => boolean
  setTenantID: (tenant: string) => void
  getTenantID: () => string
  setDateRange: (from: Date, to: Date) => void
  getDateRange: () => { from: Date; to: Date } | undefined
  getSearchQuery: () => string | undefined
  setSearchQuery: (value: string) => void
  setEventLevel: (level: any) => void
  getEventLevel: () => string
}

export const ApplicationContext = createContext<
  ApplicationContextProps | undefined
>(undefined)

const ApplicationIndex = ({ children }: any): JSX.Element => {
  const [selectTenant, setSelectTenant] = useState<boolean>(false)
  const [tenantId, setTenantId] = useState<any>(undefined)
  const [range, setRange] = useState<{ from: Date; to: Date }>()
  const [query, setQuery] = useState<string>('')
  const [level, setLevel] = useState<string>('')

  const memoizedSearchQuery = useMemo(() => query, [query])

  return (
    <ApplicationContext.Provider
      value={{
        showSelector: () => selectTenant,
        modifyTenant: value => setSelectTenant(value),
        setTenantID: value => setTenantId(value),
        getTenantID: () => {
          if (tenantId === undefined) {
            return undefined
          }

          return tenantId
        },
        setDateRange: (from, to) => setRange({ from, to }),
        getDateRange: () => range,
        setSearchQuery: value => setQuery(value),
        getSearchQuery: () => memoizedSearchQuery,
        setEventLevel: value => setLevel(value),
        getEventLevel: () => level,
      }}
    >
      <StackedLayout>{children}</StackedLayout>
    </ApplicationContext.Provider>
  )
}

export default ApplicationIndex
