/* eslint-disable import/no-anonymous-default-export */
import { Card, Title } from '@tremor/react'
import { Pie } from '@nivo/pie'

export default ({ data = [] }: { data: any[] }) => {
  const colorByIndex = (d: any) => {
    if (d.id === 'Completed') return '#22c55e'
    if (d.id === 'Completed with Warnings') return '#eab308'
    if (d.id === 'Completed With Errors') return '#f97316'
    if (d.id === 'Failed') return '#ef4444'
    return d.color
  }

  return (
    <Card>
      <Title>Integration Events - Pie Chart</Title>
      <Pie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        fit={false}
        colors={colorByIndex}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={120}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={30}
        width={475}
        height={475}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
    </Card>
  )
}
