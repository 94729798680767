import { PlusIcon } from '@heroicons/react/24/outline'
import { useState, Fragment } from 'react'
import CreateNewScheduleModal from './modal'

/** @internal */
export default (): JSX.Element => {
  // init state
  const [isOpen, setIsOpen] = useState(false)

  // compose JSX
  return (
    <div className="mt-2">
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Schedule
      </button>
      <CreateNewScheduleModal isOpen={isOpen} close={() => setIsOpen(false)} />
    </div>
  )
}
