import { PartialEmail } from '../../../../../../../types/notifications'
import { Color, Icon } from '@tremor/react'
import { FunnelIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import FiltersModal from './filters-modal'
import { OutboundMessageStatus } from 'postmark/dist/client/models'

export const FILTER_KEYS = [
  'recipient',
  'tag',
  'status',
  'dateRange',
  'subject',
] as const

export type FilterKeys = (typeof FILTER_KEYS)[number]

export type FiltersConfig = Partial<
  {
    [key in Exclude<FilterKeys, 'status' | 'dateRange'>]: string
  } & {
    status: OutboundMessageStatus
    dateRange: [Date, Date]
  }
> & {
  filterCount: number
}

export default ({
  types,
  modalIsOpen,
  currentEmails,
  currentFilters,
  openModal,
  closeModal,
  updateFilters,
}: {
  types: readonly FilterKeys[]
  currentFilters: FiltersConfig
  modalIsOpen: boolean
  currentEmails: PartialEmail[]
  closeModal: () => void
  openModal: () => void
  updateFilters: (filters: FiltersConfig) => void
}) => {
  return (
    <div className="flex flex-row justify-between items-center m-2">
      <FiltersModal
        isOpen={modalIsOpen}
        close={closeModal}
        types={types}
        currentFilters={currentFilters}
        currentEmails={currentEmails}
        onFiltersChanged={updateFilters}
      />
      <div className="flex flex-row align-middle">
        <Icon icon={FunnelIcon} />
        <div className="px-1.5 py-1.5 text-sm font-semibold text-gray-900">
          Filters
        </div>
      </div>
      <div className="flex">
        {currentFilters.filterCount > 0 &&
          (
            Object.keys(currentFilters).filter(
              key => key !== 'filterCount'
            ) as FilterKeys[]
          ).map(key => (
            <button className="flex outline-blue-500 outline hover:outline-blue-700 text-gray-800 px-1 mx-1 rounded shadow text-sm">
              <span className="p-1">
                <span className="font-bold">
                  {key[0].toUpperCase() + key.substring(1)}:{' '}
                </span>
                {_filterValue(key, currentFilters)}
              </span>
              <Icon
                onClick={() => {
                  const newFilters = {
                    ...currentFilters,
                    filterCount: currentFilters.filterCount - 1,
                  }
                  delete newFilters[key as FilterKeys]

                  updateFilters(newFilters)
                }}
                icon={XMarkIcon}
              />
            </button>
          ))}
      </div>
      <div className="flex flex-row justify-self-end">
        <button onClick={() => openModal()}>
          <Icon icon={PlusIcon} />
        </button>
        {currentFilters.filterCount > 0 && (
          <button>
            <Icon
              icon={XMarkIcon}
              onClick={() => {
                const newFilters = {
                  filterCount: 0,
                }

                updateFilters(newFilters)
              }}
            />
          </button>
        )}
      </div>
    </div>
  )
}

const _filterValue = (key: FilterKeys, filters: FiltersConfig) => {
  const value = filters[key as FilterKeys]
  if (Array.isArray(value)) {
    const start = value?.[0] as Date | undefined
    const end = value?.[1] as Date | undefined

    return `${start !== undefined ? start?.toUTCString() + ' to ' : ''}${
      end?.toUTCString() ?? ''
    }`
  } else {
    return value as string
  }
}
