import { LogoutOptions } from '@auth0/auth0-react'
import Api from '../../util/api'

export type CreateTenantDto = {
  details: {
    id: string
    name: string
    organisation: string
  }
  client: WorkdayCredentialsSecrets & {
    type: 'WQL'
  }
}

export type WorkdayCredentialsSecrets = {
  ['access-token-url']: string
  ['wql-api-url']: string
  ['refresh-token']: string
  ['client-id']: string
  ['client-secret']: string
}

export const logOut = async (
  auth0Logout: (options?: LogoutOptions) => Promise<void>
) => {
  auth0Logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  })
}

export const acceptTOS = async () => {
  await Api.post('v1/user/accept-tos', { accepted: true })
}

export const createTenant = async (
  input: CreateTenantDto
): Promise<{
  success: boolean
  message?: string
  status: string
}> => {
  let data:
    | {
        success: boolean
        message?: string
        status: string
      }
    | undefined

  try {
    data = await Api.post<{
      success: boolean
      message?: string
      status: string
    }>('v1/tenant', input)
  } catch (error: any) {
    return {
      success: false,
      message: data?.message ?? error?.message,
      status: data?.status ?? 'CREATE_TENANT_ERROR',
    }
  }

  return data
}
