import { Flex } from '@tremor/react'
import { classNames } from '../../util/styles'
const { PUBLIC_URL } = process.env

export default (
  {
    size,
    type,
  }: {
    size?: 'sm' | 'md' | 'lg'
    type?: 'text' | 'image'
  } = {
    size: 'md',
    type: 'image',
  }
) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      {type === 'text' ? <Text size={size} /> : <Image size={size} />}
      <Trademark size={size} />
    </Flex>
  )
}

export const Text = ({ size }: { size?: 'sm' | 'md' | 'lg' }) => (
  <span
    className={classNames(
      'mb-2 font-bold text-sky-950 dark:text-sky-200 uppercase',
      size === 'sm' ? 'text-xl' : size === 'lg' ? 'text-4xl' : 'text-2xl'
    )}
  >
    Loopwatch
  </span>
)

export const Trademark = ({ size }: { size?: 'sm' | 'md' | 'lg' }) => (
  <span
    className={classNames(
      'text-slate-700 dark:text-slate-200 mb-5 ml-1',
      size === 'sm' ? 'text-base' : size === 'lg' ? 'text-2xl' : 'text-lg'
    )}
  >
    ®
  </span>
)

export const Image = ({ size }: { size?: 'sm' | 'md' | 'lg' }) => (
  <img
    className={classNames(
      size === 'sm' ? 'h-3' : size === 'lg' ? 'h-5' : 'h-4'
    )}
    src={`${PUBLIC_URL}/loopwatch.png`}
    alt="Loopwatch"
    title="Loopwatch"
  />
)
