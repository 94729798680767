import { QueryFunctionContext } from 'react-query'
import Api from '../../util/api'

export const fetchIntegrations = async (
  context: QueryFunctionContext<
    [
      key: string,
      period: string,
      level: string,
      tenant: string | undefined,
      range: any | undefined
    ]
  >
) => {
  const { queryKey } = context
  const [_, period, level, tenant = 'none', range] = queryKey

  const data = await Api.post<{
    data: any
    status: string
  }>('v1/stats/integration', {
    period,
    level,
    tenant,
    range,
  })

  return data.data
}
