export type User = {
  id?: string
  name: string
  email: string
  roles: Roles[]
  emailVerified: boolean
  accessToken?: string
  refreshToken?: string
  org: string
}

export enum Roles {
  Analyst = 'analyst',
  Admin = 'admin',
  Billing = 'billing',
  SuperUser = 'superuser',
}

export default User
