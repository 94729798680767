import { Fragment, useRef, useState } from 'react'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { Icon } from '@tremor/react'
import { Dialog, Transition } from '@headlessui/react'
import Organisation, { Plans } from '../../../types/organisation'
import { useMutation } from 'react-query'
import { createOrg, updateOrg } from '../http'
import ActivateButton from './activate-button'

/**
 * Component for the modal that appears when the user clicks the "Create New Org" button or "Configure" button for an existing org.
 *
 * @component
 */
export default function ({
  isOpen,
  close,
  org,
}: {
  isOpen: boolean
  close: () => void
  org?: Organisation
}) {
  // init state
  const cancelButtonRef = useRef(null)
  const mutator = org
    ? useMutation(['update-org', org], updateOrg)
    : useMutation(['create-org'], createOrg)

  /**
   * Handles the form submission.
   */
  const onSubmit = (e: React.FormEvent) => {
    // get the input values
    e.preventDefault()
    if (!!org?.id) {
      // TODO: add new org via mutator
    } else {
      // TODO: update existing org via mutator
    }
  }

  // compose jsx
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      {/* Modal Container */}
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Modal Contents */}
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {mutator.status === 'loading' ? (
                  <div>Loading...</div>
                ) : (
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      {/* Modal Title */}
                      <_Title org={org} />
                      <div className="text-left border-b border-gray-900/10 pb-12 pt-3">
                        <_Form org={org} onSubmit={onSubmit} />
                      </div>
                    </div>
                  </div>
                )}
                {/* Modal Footer */}
                <_ModalButtons
                  close={close}
                  cancelButtonRef={cancelButtonRef}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

/**
 * @internal
 * @component
 */
const _Form = ({
  org,
  onSubmit,
}: {
  org?: Organisation
  onSubmit: React.FormEventHandler
}): JSX.Element => (
  <form onSubmit={onSubmit}>
    <_FormRow>
      <_Input
        name="Name"
        desc="The name of the organization"
        defaultValue={org?.name}
        required
      />
      <_Select
        name="Plan Type"
        desc="Type of Plan for this Organization"
        options={['Standard', 'Advanced']}
        defaultIndex={org?.plan === Plans.Advanced ? 1 : 0}
        required
      />
    </_FormRow>
  </form>
)

/**
 * @internal
 * @component
 */
const _FormRow = ({
  children,
}: {
  children: JSX.Element | JSX.Element[]
}): JSX.Element => (
  <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
    {children}
  </div>
)

/** @internal */
const _Label = ({
  name,
  desc,
  required = false,
}: {
  name: string
  desc: string
  required?: boolean
}): JSX.Element => (
  <label
    title={desc}
    htmlFor={_getIdFromName(name)}
    className="block text-sm font-medium leading-6 text-gray-900"
  >
    {required && <span color="red">* </span>}
    {name}
    <Icon icon={QuestionMarkCircleIcon} size="xs" />
  </label>
)

/**
 * @internal
 * @component
 */
function _Title({ org }: { org?: Organisation }): JSX.Element {
  return (
    <Dialog.Title
      as="h3"
      className="text-base font-bold leading-6 text-gray-900 border-b border-gray-900/10 pb-2"
    >
      {org ? (
        <span>
          Editing Organisation: <span className="underline">{org.name}</span>
        </span>
      ) : (
        'Create a new Organization'
      )}
    </Dialog.Title>
  )
}

/**
 * @internal
 * @component
 */
function _Input({
  name,
  desc,
  defaultValue = '',
  required = false,
  type = 'text',
}: {
  name: string
  desc: string
  defaultValue?: string
  type?: React.HTMLInputTypeAttribute
  required?: boolean
}): JSX.Element {
  // init consts
  const id = _getIdFromName(name)

  // compose jsx
  return (
    <div className="sm:col-span-3">
      <_Label name={name} desc={desc} required={required} />
      <div>
        <input
          type={type}
          name={id}
          id={id}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder={desc}
          defaultValue={defaultValue}
          required={required}
        />
      </div>
    </div>
  )
}

/**
 * @internal
 * @component
 */
function _Select({
  name,
  options,
  desc,
  defaultIndex = 0,
  required = false,
}: {
  name: string
  desc: string
  options: string[]
  defaultIndex?: number
  required?: boolean
}): JSX.Element {
  // init consts
  const id = _getIdFromName(name)

  // compose jsx
  return (
    <div className="sm:col-span-3">
      <_Label name={name} desc={desc} required={required} />
      <div>
        <select
          id={id}
          name={id}
          defaultValue={options[defaultIndex]}
          placeholder={desc}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          required={required}
        >
          {options.map((option, index) => (
            <option key={index}>{option}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

/**
 * @internal
 * @component
 */
const _ModalButtons = ({
  close,
  cancelButtonRef,
}: {
  close: () => void
  cancelButtonRef: React.LegacyRef<any>
}): JSX.Element => (
  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
    <button
      type="submit"
      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
    >
      Submit
    </button>
    <button
      type="button"
      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
      onClick={close}
      ref={cancelButtonRef}
    >
      Cancel
    </button>
  </div>
)

/**
 * @internal
 * @component
 */
function _getIdFromName(name: string) {
  return name.replace(' ', '-').toLowerCase()
}
