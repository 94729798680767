import { Card } from '@tremor/react'
import { User } from '@auth0/auth0-spa-js'
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { UserUpdater, fetchUserByEmail, updateUser } from './http'
import { WQLTenantModal } from './modals/atom'
import { UpdateWQLTenantModal } from './modals/wql-tenant-modal'
import { useRecoilState } from 'recoil'

type UserData = User & {
  password?: string
  password2?: string
}

// TODO: passwords submit will currently return an error from the Rest API. We need to chage it to the Auth0 API.
const Profile = () => {
  const [user, setUser] = useState<UserData | undefined>(undefined)
  const [details, setDetails] = useRecoilState(WQLTenantModal)

  const { user: auth0 } = useAuth0()
  useQuery(['get-current-user', auth0?.email ?? ''], fetchUserByEmail, {
    onSuccess: (user: User) => {
      setUser({
        ...user,
      })
    },
  })

  const update: UserUpdater = useMutation(['update-user', user?.id], updateUser)

  return (
    <>
      <Card>
        <main className="px-4 py-1 sm:px-6 lg:flex-auto lg:px-0 lg:py-6">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Profile
              </h2>
              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                    Full name
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">
                      {user?.name !== undefined ? user?.name : 'Unknown'}{' '}
                    </div>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                    Email address
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{auth0?.email}</div>
                  </dd>
                </div>
              </dl>
            </div>

            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Plan Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                Modify your plan details or verify your account.
              </p>

              <ul
                role="list"
                className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"
              >
                <li className="flex justify-between gap-x-6 py-6">
                  <div className="font-medium text-gray-900">
                    Billing Portal
                  </div>
                  <button
                    type="button"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Continue
                  </button>
                </li>
              </ul>
            </div>

            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Integrations
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                Connect and sync tenants to your account.
              </p>

              <ul
                role="list"
                className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"
              >
                <li className="flex justify-between gap-x-6 py-6">
                  <div className="font-medium text-gray-900">WQL Tenant</div>
                  <button
                    type="button"
                    onClick={() => setDetails({ ...details, active: true })}
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Update
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </main>
      </Card>
      <UpdateWQLTenantModal />
    </>
  )
}

export default withAuthenticationRequired(Profile)
