const { PUBLIC_URL } = process.env

const SIZES = {
  xs: 6,
  sm: 12,
  md: 24,
  lg: 36,
  xl: 64,
  xxl: 128,
}

export default ({ size }: { size?: keyof typeof SIZES }) => {
  const scale = SIZES[size ?? 'md']

  return (
    <img
      className={`h-${scale} w-${scale}`}
      src={`${PUBLIC_URL}/icon-512x512.png`}
      alt="Loopwatch"
      title="Loopwatch"
    />
  )
}
