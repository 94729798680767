import TabHeader from '../../../../../../components/tabs/header'
import TabCard from '../../../../../../components/tabs/card'
import Table, { SortConfig } from './components/table'
import Filters, { FILTER_KEYS, FiltersConfig } from './components/filters'
import { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { TenantContext } from '../../../../../../layout/stacked'
import { PartialEmail, Email } from '../../../../../../types/notifications'
import { getRecentEmails } from './http'

export default () => {
  const tenant = useContext(TenantContext)
  const [filters, setFilters] = useState<FiltersConfig>({ filterCount: 0 })
  const [sorts, setSorts] = useState<SortConfig>([])
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const { data: emails } = useQuery(
    [
      'get-recent-notification-emails',
      tenant,
      {
        ...filters,
        count: 25,
      },
    ],
    getRecentEmails
  )

  const sortEmails = (emails: PartialEmail[]): PartialEmail[] => {
    for (const [key, direction] of sorts) {
      emails.sort((a, b) => {
        const _1 =
          key === 'Recipient'
            ? a.To[0].Email
            : key === 'ReceivedAt'
            ? new Date(a[key])
            : a[key]
        const _2 =
          key === 'Recipient'
            ? b.To[0].Email
            : key === 'ReceivedAt'
            ? new Date(b[key])
            : b[key]

        if (_1 === _2) {
          return 0
        }

        if (direction == 'asc') {
          return _1 < _2 ? 1 : -1
        } else {
          return _1 > _2 ? 1 : -1
        }
      })
    }

    return emails
  }

  return (
    <>
      <TabHeader
        title="Emails"
        description="A list of the recent notification emails for a given Tenant."
      />
      <TabCard>
        <Filters
          types={FILTER_KEYS}
          modalIsOpen={showFiltersModal}
          currentEmails={emails ?? []}
          currentFilters={filters}
          openModal={() => setShowFiltersModal(true)}
          closeModal={() => setShowFiltersModal(false)}
          updateFilters={setFilters}
        />
        <Table
          emails={sortEmails(emails ?? [])}
          sorts={sorts}
          updateSort={setSorts}
        />
      </TabCard>
    </>
  )
}
