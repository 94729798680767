import Name from '../branding/name'
import Spinner from './spinner'

/**
 * Loopwatch Loading Spinner Splash-Panel
 *
 * @component
 */
export default ({ showBranding }: { showBranding?: boolean }) => (
  <div className="flex flex-col items-center justify-center p-10">
    <span className="mb-8">{(showBranding ?? true) && <Name size="lg" />}</span>
    <Spinner />
  </div>
)
