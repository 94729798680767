/* eslint-disable import/no-anonymous-default-export */
import React, { Fragment, useContext } from 'react'
import Organisation, { PLAN_NAMES } from '../../../types/organisation'
import { Cog8ToothIcon } from '@heroicons/react/24/outline'
import Modal from './modal'
import { OrganisationContext } from '../pages/detail-page/context'
import { useNavigate } from 'react-router-dom'

/**
 *  React Component for the orgs table
 *
 *  @component
 */
export default function (props: { orgs: Organisation[] }): JSX.Element {
  // compose jsx
  return (
    <div className="mt-6 overflow-hidden border-t border-gray-100">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <table className="table-fixed w-full text-left divide-y divide-gray-300">
            <_Headers />
            <tbody>
              {props?.orgs?.map(org => (
                <_OrgRow {...org} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

/**
 * @component
 * @internal
 */
function _Headers() {
  return (
    <thead>
      <tr>
        <th>Info</th>
        <th className="hidden lg:table-cell text-left">Tenants</th>
        <th className="text-right">Actions</th>
      </tr>
    </thead>
  )
}

/**
 * @component
 * @internal
 */
function _OrgRow(org: Organisation): JSX.Element {
  const context = useContext(OrganisationContext)

  return (
    <tr key={org.id} className="even:bg-gray-50">
      <td className="relative py-5 pr-6">
        <_Info {...org} />
        <div className="lg:hidden my-2">
          {org.tenants.length !== 0 && (
            <Fragment>
              <_TenantsLabel />
              <_Tenants {...org} />
            </Fragment>
          )}
        </div>
      </td>
      <td className="hidden py-5 pr-6 lg:table-cell text-left">
        <_Tenants {...org} />
      </td>
      <td className="py-5 text-right align-top">
        <_Actions {...org} />
      </td>
    </tr>
  )
}

/**
 * @component
 * @internal
 */
function _Info(org: Organisation) {
  return (
    <div className="flex gap-x-6">
      <div className="flex-auto">
        <div className="flex items-start gap-x-3">
          <_Name {...org} />
          <_Plan {...org} />
        </div>
        <_Id {...org} />
      </div>
    </div>
  )
}

/**
 * @component
 * @internal
 */
function _Plan(org: Organisation) {
  return (
    <div className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset">
      {PLAN_NAMES[org.plan]}
    </div>
  )
}

/**
 * @component
 * @internal
 */
function _Id(org: Organisation) {
  return (
    <div className="mt-1 text-xs leading-5 text-gray-500">id: {org.id}</div>
  )
}

/**
 * @component
 * @internal
 */
function _Name(org: Organisation) {
  return (
    <div className="text-md font-medium leading-6 text-gray-900">
      {org.name}
    </div>
  )
}

/**
 * @component
 * @internal
 */
function _Tenants(org: Organisation) {
  return (
    <Fragment>
      <span className="inline-flex max-w-[150px] items-center rounded-full bg-blue-50 px-2 py-1.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
        {org.tenants.length}
      </span>
    </Fragment>
  )
}

/**
 * @component
 * @internal
 */
function _TenantsLabel() {
  return (
    <Fragment>
      <label
        htmlFor="tenants"
        className="mx-1 font-small text-sm text-gray-900"
      >
        Tenants:
      </label>{' '}
    </Fragment>
  )
}

/**
 * @component
 * @internal
 */
function _Actions(org: Organisation, callback: () => void) {
  return (
    <div className="flex flex-wrap gap-2 justify-end md:max-lg:grid">
      <_ConfigureButton org={org} />
    </div>
  )
}

/**
 * @component
 * @internal
 */
function _ConfigureButton({ org }: { org: Organisation }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const navigate = useNavigate()

  return (
    <button
      type="button"
      title="View Information"
      className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      onClick={() => {
        navigate(`/organisations/${org.id}`)
      }}
    >
      <Cog8ToothIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
      <span className="hidden sm:inline">View Information</span>
      <Modal org={org} isOpen={isOpen} close={() => setIsOpen(false)} />
    </button>
  )
}
