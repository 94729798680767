/* eslint-disable import/no-anonymous-default-export */
import { Card, Title, Text, Tab, TabList, Flex } from '@tremor/react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useMemo } from 'react'
import { useState } from 'react'
import Table, { Row } from './components/table'
import Filters from './components/filters'
import AddScheduleModalButton from './components/add-schedule-button'
import { useQuery } from 'react-query'
import Api from '../../util/api'

export enum AnalyticsTab {
  Overview = 'ANALYTICS_OVERVIEW',
  Events = 'ANALYTICS_EVENTS',
  DetailedView = 'ANALYTICS_DETAILED_VIEW',
}

/**
 * React Component for the Outline of the Analytics page.
 *
 * @component
 */
const ScheduleOutline = (): JSX.Element => {
  const [notifications, setNotifications] = useState<Row[]>([])

  const { data: response, status } = useQuery(
    ['notification-schedule'],
    async () => {
      const { data } = await Api.get<any>(`v1/notifications/schedule`)
      return data
    }
  )

  const schedules = useMemo(() => {
    if (typeof response === 'undefined') {
      return [{ id: 'schedule' }]
    } else {
      return response
    }
  }, [status])

  return (
    <>
      <main>
        <Title>Email Schedule</Title>
        <Text>
          A general overview of email schedules for different recipients, each
          one will send out a periodic email of latest charts.
        </Text>
        <div className="relative mt-4 mb-4">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center"></div>
        </div>
        <>
          <div className="mt-6">
            {/* Tabs */}
            <div className="flex justify-between mb-4">
              <AddScheduleModalButton />
            </div>
            <Flex className="mt-2">
              <Card>
                <Table rows={schedules} />
              </Card>
            </Flex>
          </div>
        </>
      </main>
    </>
  )
}

export default withAuthenticationRequired(ScheduleOutline)
