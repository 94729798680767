import { createContext, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import {
  ChartBarIcon,
  HomeIcon,
  CalendarIcon,
} from '@heroicons/react/24/outline'
import { ApplicationContext, ApplicationContextProps } from '../Application'
import { Header } from './components/nav-header'
import Footer from './components/footer'

export const TenantContext = createContext<string | undefined>(undefined)

const NAVIGATION = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  { name: 'Analytics', href: '/analytics', icon: ChartBarIcon },
  {
    name: 'Organisations',
    href: '/organisations',
    icon: CalendarIcon,
  },
]

const StackedLayout: React.FC<any> = ({ children }: any) => {
  const { user, logout, isAuthenticated, loginWithRedirect, isLoading } =
    useAuth0()

  const navigate = useNavigate()
  const { setTenantID } = useContext(
    ApplicationContext
  ) as ApplicationContextProps
  const onTenantChange = (value: any) => {
    setTenantID(value.id)
  }

  useEffect(() => {
    async function checkUser() {
      if (isAuthenticated === true && isLoading === false) {
        console.log({ test: 'logged in' })
      }

      if (isAuthenticated === false && isLoading === false) {
        console.log({ test: 'logged out' })
        await loginWithRedirect()
      }
    }

    checkUser()
  }, [isAuthenticated, isLoading, loginWithRedirect])

  const navigation = [
    { name: 'Dashboard', href: '/', current: true },
    { name: 'Analytics', href: '/analytics', current: false },
  ]

  const userNavigation = [
    { name: 'Your profile', onClick: () => navigate('/profile') },
    { name: 'Users', onClick: () => navigate('/users') },
    { name: 'Sign out', onClick: () => logout({}) },
  ]

  return (
    <>
      <div className="min-h-full">
        {/* Navigation Header */}
        <Header
          user={user}
          onTenantChange={onTenantChange}
          navigation={navigation}
          userNavigation={userNavigation}
        />
        {/* Page Contents */}
        <div className="pt-10 mb-4">
          <main>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              {children}
              <Footer />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default StackedLayout
