/* eslint-disable import/no-anonymous-default-export */
import { Card, Flex } from '@tremor/react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Header from './components/header'
import Table from './components/table'
import { useQuery, useMutation } from 'react-query'
import { fetchOrgs, createOrg } from './http'

/**
 *  React Component for the main layout and content of the Orgs page
 *
 *  @component
 */
const Organisations = (): JSX.Element => {
  // init state
  const orgs = useQuery(['get-organisations'], fetchOrgs)
  const addNewOrg = useMutation(['add-organisation'], createOrg)

  return (
    <main>
      <Header createOrg={addNewOrg} />
      <Flex className="mt-6">
        <Flex>
          <Card>
            {orgs.status !== 'loading' ? (
              <Table orgs={orgs.data!} />
            ) : (
              <div>Loading...</div>
            )}
          </Card>
        </Flex>
      </Flex>
    </main>
  )
}

export default withAuthenticationRequired(Organisations)
