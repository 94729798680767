import { atom } from 'recoil'

export const RAASTenantModal = atom({
  key: 'raas-tenant-modal', // unique ID (with respect to other atoms/selectors)
  default: {
    id: '',
    ussrname: '',
    password: '',
    url: '',
    active: false,
  }, // default value (aka initial value)
})

export const WQLTenantModal = atom({
  key: 'wql-tenant-modal', // unique ID (with respect to other atoms/selectors)
  default: {
    id: '',
    clientId: '',
    clientSecret: '',
    refreshToken: '',
    tokenURL: '',
    wqlURL: '',
    active: false,
  }, // default value (aka initial value)
})
