import React, { Fragment, useContext } from 'react'
import Integration from '../../../../../types/integration'
import { TenantContext } from '../../../../../layout/stacked'


/**
 *  React Component for the integrations table
 *
 *  @component
 */
export default (props: {
  integrations: any[]
  showTenants: boolean
}): JSX.Element => {
  // compose jsx
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Count
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {props.integrations.map(integration => (
                  <tr key={integration.email}>
                    <td className="whitespace-nowrap py-7 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {integration.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {integration.count}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * @componentd
 * @internal
 */
function _Headers() {
  return (
    <thead>
      <tr>
        <th>Name</th>
        <th className="hidden sm:table-cell">Events</th>
      </tr>
    </thead>
  )
}

/**
 * @component
 * @internal
 */
function _IntegrationRow({
  integration,
  showTenant,
}: {
  integration: Integration
  showTenant: boolean
}): JSX.Element {
  return (
    <tr key={integration.event} className="even:bg-gray-50">
      <td className="py-5 pr-6">
        <_Info integration={integration} showTenant={showTenant} />
      </td>
      <td className="py-5 pr-6 hidden sm:table-cell">
        <_NumberOfEvents {...integration} />
      </td>
    </tr>
  )
}

/**
 * @component
 * @internal
 */
function _Info({
  integration,
  showTenant,
}: {
  integration: Integration
  showTenant: boolean
}) {
  return (
    <div className="flex gap-x-6">
      <div className="flex-auto">
        <div className="block gap-x-3">
          <_Name {...integration} />
        </div>
        {showTenant && <_Tenant {...integration} />}
        <div className="flex sm:hidden">
          <_NumberOfEvents {...integration} />
          <_NumberOfUsers {...integration} />
        </div>
      </div>
    </div>
  )
}

/**
 * @component
 * @internal
 */
function _Name(integration: Integration) {
  return (
    <span className="text-md font-medium leading-6 text-gray-900 break-all lg:break-normal lg:break-words">
      {integration.name}
    </span>
  )
}

/**
 * @component
 * @internal
 */
function _NumberOfEvents(integration: Integration) {
  return (
    <div className="text-sm leading-6 text-gray-900 p-1 sm:p-0">
      <span className="sm:hidden">Events: </span>
      {integration.numberOfEvents}
    </div>
  )
}

/**
 * @component
 * @internal
 */
function _NumberOfUsers(integration: Integration) {
  return (
    <div className="text-sm leading-6 text-gray-900 p-1 sm:p-0">
      <span className="sm:hidden">Users: </span>
      {integration.numberOfUsers}
    </div>
  )
}

/**
 * @component
 * @internal
 */
function _Tenant(integration: Integration) {
  return (
    <div className="mt-1 text-xs leading-5 text-gray-500">
      tenant: {integration.tenant}
    </div>
  )
}
