/* eslint-disable import/no-anonymous-default-export */
import BarChart from './bar-chart'
import DonutChart from './donut-chart'
import { PlusIcon, ChartBarIcon } from '@heroicons/react/24/solid'
import { useQuery } from 'react-query'
import http from './http'
import { useContext } from 'react'
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../../../layout/Application'

const EmptyState = () => {
  return (
    <div className="text-center">
      <ChartBarIcon
        className="mx-auto h-12 w-12 text-gray-400"
        aria-hidden="true"
      />
      {/* Using ChartBarIcon instead of PlusIcon for a more relevant visualization */}
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        No Data Available
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        There's currently no data to display. Ensure data sources are connected
        or wait for updates.
      </p>
      <div className="mt-6">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Reload Page
        </button>
      </div>
    </div>
  )
}

export default () => {
  const { getTenantID, getDateRange } = useContext(
    ApplicationContext
  ) as ApplicationContextProps

  const tenant = getTenantID()
  const range = getDateRange()

  const { data: bar_data } = useQuery(
    ['bar-chart', 'BAR_CHART', 'PERIOD_WEEKLY', tenant, range],
    http.queryGraph
  )

  const { data: pie_data } = useQuery(
    ['pie-chart', 'PIE_CHART', 'PERIOD_WEEKLY', tenant, range],
    http.queryGraph
  )
  return (
    <div className="mt-6">
      {pie_data?.count === 0 ? (
        <EmptyState />
      ) : (
        <div className="grid grid-cols-2 gap-4">
          <DonutChart data={pie_data?.data ?? []} />
          <BarChart data={bar_data?.data ?? []} />
        </div>
      )}
    </div>
  )
}
