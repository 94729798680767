import { Fragment, useEffect, useRef, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

const levels = [
  { name: 'Completed' },
  { name: 'Completed with Warnings' },
  { name: 'Completed With Errors' },
  { name: 'Failed' },
]

export default function FilterDropdown({ onUpdate }: any) {
  const [selected, setSelected] = useState(levels[0])
  const listboxRef = useRef<any>(null)

  useEffect(() => {
    if (onUpdate) onUpdate(selected)
  }, [selected])

  useEffect(() => {
    if (listboxRef.current) {
      const longestOption = levels.reduce((longest, level) => {
        return level.name.length > longest.length ? level.name : longest
      }, '')

      const tempSpan = document.createElement('span')
      tempSpan.innerHTML = longestOption
      tempSpan.className = 'block truncate px-4 py-2'
      document.body.appendChild(tempSpan)

      const width = tempSpan.offsetWidth
      document.body.removeChild(tempSpan)

      listboxRef.current.style.width = `${width}px`
    }
  }, [])

  return (
    <div className="">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button
            ref={listboxRef}
            style={{ maxWidth: '250px' }}
            className="flex items-center justify-center w-full px-4 py-2 pr-8 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            <span className="block truncate">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {levels.map((level, levelIdx) => (
                <Listbox.Option
                  key={levelIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-violet-100 text-violet-900' : 'text-gray-900'
                    }`
                  }
                  value={level}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {level.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-violet-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
