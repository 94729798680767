/* eslint-disable import/no-anonymous-default-export */
import { Card, Flex, SelectBox, SelectBoxItem } from '@tremor/react'
import Table from './components/table'
import { fetchPaginatedIntegrations } from './http'
import { useContext, useEffect, useState } from 'react'
import { EventLevel } from '../../../../types/event'
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../../../layout/Application'
import PaginationFooter from '../../../../components/pagination-footer'
import usePagination from '../../../../hooks/usePagination'


/**
 *  React Component for the main layout and content of the Integrations tab
 *
 *  @component
 */
export default function (): JSX.Element {
  // init state

  const { getTenantID, getDateRange } = useContext(
    ApplicationContext
  ) as ApplicationContextProps

  const tenant = getTenantID()
  const range = getDateRange()

  const [level, setLevel] = useState<EventLevel | 'All'>(EventLevel.Completed)
  const [order, setOrder] = useState<string>('Ascending')

  const {
    status,
    data,
    page,
    totalPages,
    totalItems,
    incrementPage,
    decrementPage,
    setDirectPage,
    refetch,
  } = usePagination({
    fetcher: fetchPaginatedIntegrations,
    params: {
      tenant,
      range,
      level,
      order,
    },
    initialPage: 1,
    initialItemsPerPage: 10,
  })

  useEffect(() => {
    refetch()
  }, [tenant, range, level, order])

  return (
    <Flex>
      <Card>
        <div className="flex justify-between">
          <h2 className="text-base font-semibold leading-6 text-gray-800">
            Top Integration Systems {tenant?.toLocaleUpperCase()}
          </h2>
          <div className="flex">
            <div className="px-2">
              <span className="p-1.5 text-md text-gray-800">Order</span>
              <SelectBox
                defaultValue="Ascending"
                value={order}
                onValueChange={order => setOrder(order)}
              >
                <SelectBoxItem key="ASCENDING" value="Ascending" />
                <SelectBoxItem key="DESCENDING" value="Descending" />
              </SelectBox>
            </div>
            <div>
              <span className="p-1.5 text-md text-gray-800">Event Status:</span>
              <SelectBox
                defaultValue={undefined}
                value={level}
                placeholder="Filter by event level"
                onValueChange={level => setLevel(level as EventLevel)}
              >
                {[undefined as EventLevel | undefined]
                  .concat(Object.values(EventLevel))
                  .map(level => (
                    <SelectBoxItem
                      key={level ?? 'All'}
                      value={level ?? 'All'}
                    />
                  ))}
              </SelectBox>
            </div>
          </div>
        </div>
        {status !== 'loading' ? (
          <Table integrations={data || []} showTenants={tenant === undefined} />
        ) : (
          <div>Loading...</div>
        )}

        <PaginationFooter
          count={20}
          page={page}
          increment={incrementPage}
          decrement={decrementPage}
          setter={setDirectPage}
          totalItems={totalItems}
          totalPages={totalPages}
        />
      </Card>
    </Flex>
  )
}
