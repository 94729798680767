/* eslint-disable import/no-anonymous-default-export */
import Table, {
  Column as TableColumn,
  Row as TableRow,
} from '../../../components/selection-table'

//#region consts

/**
 * The columns for the notifications table.
 */
export const COLUMNS = [
  {
    name: 'Job ID',
    key: 'id',
    transform: (value: string) => <b>{value}</b>,
  },
  {
    name: 'Tenant',
    key: 'tenant',
  },
  {
    name: 'Title',
    key: 'title',
  },
  {
    name: 'Timespan',
    key: 'timespan',
  },
  {
    name: 'Recipient',
    key: 'recipient',
  },
  {
    name: 'Is Active?',
    key: 'active',
    transform: (value: boolean) => (value ? 'Yes' : 'No'),
  },
] as const

//#endregion

//#region types

/**
 * A key for a column in the notifications table.
 */
export type ColumnKey = (typeof COLUMNS)[number]['key']

/**
 * A name for a column in the notifications table.
 */
export type ColumnName = (typeof COLUMNS)[number]['name']

/**
 * A column for the notifications table.
 */
export type Column = TableColumn<ColumnKey, ColumnName>

/**
 * A row for the notifications table.
 */
export type Row = TableRow<typeof COLUMNS>

//#endregion

//#region components

/**
 *  React Component for the main content of the Notifications page
 *
 *  @component
 */
export default ({ rows }: { rows: any[] }): JSX.Element => (
  <Table columns={COLUMNS} rows={rows} getRowId={row => row.id as string} />
)

//#endregion

//#region test data

/**
 * Test shape of a row in the table.
 * // TODO: remove this when we have the real types implemented
 *
 * @test
 */
export type TestRow = {
  active: boolean
  id: string
  recipient: number
  tenant: string
  timespan: string
  title: string
}

/**
 * Notifications data rows for testing the table.
 * // TODO: remove this when we have the real types implemented
 *
 * @test
 */
export const TEST_ROWS: Row[] = []
