/* eslint-disable import/no-anonymous-default-export */
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import ApplicationIndex from './Application'
import Login from '../pages/login'
import Dashboard from '../pages/dashboard'
import Jobs from '../pages/jobs'
import Settings from '../pages/settings'
import EventDetail from '../pages/event-detail'
import AnalyticsOutline from '../pages/analytics'
import Organisations from '../pages/organisations'
import FullEmailView from '../pages/organisations/pages/full-email'
import DetailPage from '../pages/organisations/pages/detail-page'
import Profile from '../pages/profile'
import axios from 'axios'
import Schedule from '../pages/schedule'
import Onboarding from '../pages/onboarding/'
import Users from '../pages/users'
import { useEffect } from 'react'

/**
 * Wrapper Component for the routes of the application.
 * - Handles authentication and authorization via Auth0.
 *
 * @component
 */
export default () => {
  const { getAccessTokenSilently } = useAuth0()

  axios.interceptors.request.use(async config => {
    const token = await getAccessTokenSilently()

    if (config.headers !== undefined) {
      config.headers['authorization'] = `Bearer ${token}`
    }

    return config
  })

  return <_Router />
}

/**
 * React Component for the routes of the application.
 *
 * @component
 * @internal
 */
const _Router = (): JSX.Element => {
  const {
    logout,
    loginWithRedirect,
    isLoading: authIsLoading,
    isAuthenticated,
  } = useAuth0()

  useEffect(() => {
    if (isAuthenticated === false && authIsLoading === false) {
      loginWithRedirect()
    }
  }, [isAuthenticated, loginWithRedirect])

  // const {
  //   isLoading: onboardingStatusIsLoading,
  //   status: onboardingStatusState,
  //   data: currentOnboardingStep,
  //   refetch: getCurrentOnboardingStep,
  // } = useQuery(
  //   [
  //     'check-onboarding',
  //     async () => {
  //       await logout()
  //       await loginWithRedirect()
  //     },
  //   ],
  //   fetchCurrentOnboardingStep,
  //   {
  //     enabled: !authIsLoading,
  //   }
  // )

  // useEffect(() => {
  //   if (onboardingStatusState === 'idle') {
  //     getCurrentOnboardingStep();
  //   }
  // }, [onboardingStatusState]);

  // if (authIsLoading) {
  //   return <LoadingSpinner />
  // } else if (onboardingStatusState === 'idle') {
  //   getCurrentOnboardingStep()
  //   return <LoadingSpinner />
  // }

  // if (onboardingStatusIsLoading) {
  //   return <LoadingSpinner />
  // }

  // if (currentOnboardingStep) {
  //   return <Onboarding step={currentOnboardingStep} />
  // }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route
          path="/"
          element={
            <ApplicationIndex>
              <Dashboard />
            </ApplicationIndex>
          }
        />
        <Route
          path="/analytics"
          element={
            <ApplicationIndex>
              <AnalyticsOutline />
            </ApplicationIndex>
          }
        />
        <Route
          path="/schedule"
          element={
            <ApplicationIndex>
              <Schedule />
            </ApplicationIndex>
          }
        />
        <Route
          path="/event/:id"
          element={
            <ApplicationIndex>
              <EventDetail />
            </ApplicationIndex>
          }
        />
        <Route
          path="/settings"
          element={
            <ApplicationIndex>
              <Settings />
            </ApplicationIndex>
          }
        />
        <Route
          path="/users"
          element={
            <ApplicationIndex>
              <Users />
            </ApplicationIndex>
          }
        />
        <Route
          path="/organisations"
          element={
            <ApplicationIndex>
              <Organisations />
            </ApplicationIndex>
          }
        />
        <Route
          path="/organisations/:id"
          element={
            <ApplicationIndex>
              <DetailPage />
            </ApplicationIndex>
          }
        />
        <Route
          path="/organisations/email/:id"
          element={
            <ApplicationIndex>
              <FullEmailView />
            </ApplicationIndex>
          }
        />
        <Route
          path="/jobs"
          element={
            <ApplicationIndex>
              <Jobs />
            </ApplicationIndex>
          }
        />
        <Route
          path="/profile"
          element={
            <ApplicationIndex>
              <Profile />
            </ApplicationIndex>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}
