import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Datepicker from 'react-tailwindcss-datepicker'
import Dropbox from '../../../../pages/dashboard/components/Dropbox'
import { fetchTenants, fetchUser } from '../../../http'
import { useQuery } from 'react-query'
import {
  ApplicationContext,
  ApplicationContextProps,
} from '../../../Application'

/**
 * The navigation header-bar's filter fields for the large stacked layout.
 *
 * @component
 */
export default ({ onTenantChange }: any) => {
  const location = useLocation()
  const { data: user, status: userStatus } = useQuery(['get-user'], fetchUser)
  const { data: tenants, status } = useQuery(['get-tenants'], fetchTenants)
  const { modifyTenant, showSelector, setDateRange, getDateRange } = useContext(
    ApplicationContext
  ) as ApplicationContextProps

  const value = {
    startDate: getDateRange()?.from || new Date(),
    endDate: getDateRange()?.to || new Date(),
  }

  const handleValueChange = (newValue: any) => {
    setDateRange(newValue.startDate, newValue.endDate)
  }

  useEffect(() => {
    const response = user as any
    if (userStatus === 'success' && response?.role === 'superuser') {
      modifyTenant(true)
    }
  }, [userStatus])

  let title

  switch (location.pathname) {
    case '/':
      title = 'Dashboard'
      break

    case '/analytics':
      title = 'Analytics'
      break

    case '/organisations':
      title = 'Organisations'
      break
  }

  return (
    <header>
      <div className="mx-auto py-4 px-4 sm:px-6 lg:px-8 grid gap-4 grid-cols-1 grid-rows-1">
        <div>
          <div className="ml-4 flex items-end">
            {status === 'success' && showSelector() && (
              <Dropbox
                title="Tenant assigned"
                placeholder="Select a tenant"
                items={tenants}
                onChange={onTenantChange}
              />
            )}
            <div className="flex items-center mr-4">
              <p className="block text-sm font-medium text-gray-700 px-4">
                Period
              </p>
              <Datepicker value={value} onChange={handleValueChange} />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
