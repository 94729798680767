import Tenant from './tenant'

export enum Plans {
  Standard = 'standard',
  Advanced = 'advanced',
}

export const PLAN_NAMES = {
  [Plans.Standard]: 'Standard',
  [Plans.Advanced]: 'Advanced',
} as const

export type Organisation = {
  id: string
  name: string
  plan: Plans
  active: boolean
  tenants: Tenant[]
}

export default Organisation
