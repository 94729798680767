import { PlusIcon } from '@heroicons/react/24/outline'
import { useState, Fragment } from 'react'
import CreateNewOrganisationModal from './modal'
import { UseMutationResult } from 'react-query'
import { OrgCreator } from '../http'

/**
 * A header component for the organisations page.
 * Also controls the modal for creating a new organisation.
 *
 * @component
 */
export default (props: { createOrg: OrgCreator }): JSX.Element => {
  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1"></div>
      <div className="mt-4 flex md:ml-4 md:mt-0">
        <_AddOrganisationModalButton createOrg={props.createOrg} />
      </div>
    </div>
  )
}

/** @internal */
const _AddOrganisationModalButton = (props: {
  createOrg: OrgCreator
}): JSX.Element => {
  // init state
  const [isOpen, setIsOpen] = useState(false)

  // compose JSX
  return (
    <Fragment>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        New Organisation
      </button>
      <CreateNewOrganisationModal
        isOpen={isOpen}
        close={() => setIsOpen(false)}
      />
    </Fragment>
  )
}
