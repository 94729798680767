import { QueryFunctionContext } from 'react-query'
import Api from '../../../../../util/api'

export type _OrganisationResponse = {
  id: string
  active: boolean
  integrations: string[]
  name: string
  plan: string
  statistics: {
    delta: string
    deltaType: string
    metric: number
    metricPrev: string
    title: string
  }
}

export const fetchOrganisation = async (
  context: QueryFunctionContext<[key?: string, id?: string]>
) => {
  const [key, id] = context.queryKey
  return await Api.get<_OrganisationResponse>(`v1/organisation/${id}`)
}
