import { QueryFunctionContext } from 'react-query'
import Api from '../../util/api'
import User from '../../types/user'

export const fetchUsers = async (
  context: QueryFunctionContext<[key: string, tenant: string | undefined]>
) => {
  const { queryKey } = context
  const [_, tenant] = queryKey

  const data = await Api.get<{ data: User[] }>('v1/user', {
    params: {
      tenant,
    },
  })

  return data.data as User[]
}

export const updateUser = async (
  id: string,
  details: { notifications: boolean; role: string }
) => {
  const data = await Api.update(`v1/user/${id}`, {
    notifications: details.notifications,
    role: details.role,
  })

  return data as any
}
