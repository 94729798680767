import { QueryFunctionContext, UseMutationResult } from 'react-query'
import Api from '../../util/api'
import User, { Roles } from '../../types/user'

type UserData = Partial<
  User & {
    password: string
  }
>

type UpdateUserDTO = {
  details: UserData
}

export const updateTenant = async (
  context: QueryFunctionContext<
    [key: string, type: string, tenant: string, details: any]
  >
) => {
  const {
    queryKey: [_, type, tenant, details],
  } = context

  return await Api.update<any>(`v1/tenant`, {
    type,
    tenant,
    details,
  })
}

export const updateUser = async (
  context: QueryFunctionContext<[key: string, id: string, mod: UserData]>
) => {
  const {
    queryKey: [_, id, mod],
  } = context

  const data = { details: {} } as UpdateUserDTO

  return await Api.update<UpdateUserDTO>(`v1/auth0/user`, data, {
    params: {
      userId: id,
    },
  })
}

export const fetchUserByEmail = async (
  context: QueryFunctionContext<[key: string, email: string]>
) => {
  const data = (await Api.get('v1/identity/user', {
    params: {
      email: context.queryKey[1],
    },
  })) as any

  return {
    name: data.name as string,
    emailVerified: data.verified === true,
    email: data.email as string,
    org: data.org as string,
    roles: data.role ? [data.role as Roles] : [],
  } as User
}

export type UserUpdater = UseMutationResult<
  undefined,
  unknown,
  QueryFunctionContext<[key: string, id: string, mod: UserData], any>,
  unknown
>
