import { Flex } from '@tremor/react'
import AppName from '../../../../components/branding/name'
import Divider from '../../../../components/tabs/divider'
import { Trademark } from '../../../../components/branding/name'

export default () => (
  <div className="mt-10">
    <Divider />
    <Flex alignItems="center" justifyContent="center" className="mt-4">
      <span className="hidden sm:inline">
        <AppName />
      </span>
      <span className="inline sm:hidden">
        <AppName size="sm" />
      </span>
      <span className="sm:text-lg ml-1 font-bold text-blue-950 dark:text-blue-200">
        Powered by Stormloop Technologies.
      </span>
    </Flex>
    <div className="text-xs text-gray-500 dark:text-gray-400 text-center">
      &copy; 2023 Stormloop Technologies. All rights reserved. Trademark
      pending.
    </div>
  </div>
)
