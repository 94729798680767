import {
  Card,
  Metric,
  Text,
  Flex,
  BadgeDelta,
  DeltaType,
  Color,
  Grid,
  Divider,
} from '@tremor/react'
import { PaperClipIcon } from '@heroicons/react/24/outline'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { fetchOrganisation } from './http'

export default () => {
  const { id } = useParams()

  const { status, data } = useQuery(
    ['organisation-overview', id],
    fetchOrganisation
  )

  const colors: { [key: string]: Color } = {
    increase: 'emerald',
    moderateIncrease: 'emerald',
    unchanged: 'orange',
    moderateDecrease: 'rose',
    decrease: 'rose',
  }

  const categories: {
    title: string
    metric: string
    metricPrev: string
    delta: string
    deltaType: DeltaType
  }[] = [
    {
      title: 'Events',
      metric: '12199',
      metricPrev: '9456',
      delta: '34.3%',
      deltaType: 'moderateIncrease',
    },
    {
      title: 'Integrations',
      metric: '10',
      metricPrev: '0',
      delta: '25.3%',
      deltaType: 'moderateIncrease',
    },
  ]

  return (
    <div>
      <div className="mt-6">
        <Grid numColsSm={2} numColsLg={3} className="gap-6">
          {categories.map(item => (
            <Card key={item.title}>
              <Text>{item.title}</Text>
              <Flex
                justifyContent="start"
                alignItems="baseline"
                className="truncate space-x-3"
              >
                <Metric>{item.metric}</Metric>
                <Text className="truncate">from {item.metricPrev}</Text>
              </Flex>
              <Flex justifyContent="start" className="space-x-2 mt-4">
                <BadgeDelta deltaType={item.deltaType} />
                <Flex justifyContent="start" className="space-x-1 truncate">
                  <Text color={colors[item.deltaType]}>{item.delta}</Text>
                  <Text className="truncate">to previous month</Text>
                </Flex>
              </Flex>
            </Card>
          ))}
        </Grid>
      </div>

      <div className="mt-6 mb-6">
        <Divider />
      </div>

      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Organisation Information
          </h3>
        </div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Name</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {data?.name}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Plan</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {data?.plan}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-900">Active</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {data?.active ? 'Active' : 'Paused'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}
