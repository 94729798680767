import { useState } from 'react'
import { PartialEmail } from '../../../../../../../types/notifications'
import { useQuery } from 'react-query'
import { getEmailDetails } from '../http'
import { Card, Icon } from '@tremor/react'
import {
  ChevronDoubleDownIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowTopRightOnSquareIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/24/outline'

const { POSTMARK_EMAIL_URL } = process.env

export type SortableKeys = 'Status' | 'Subject' | 'ReceivedAt' | 'Recipient'

export type SortConfig = [SortableKeys, 'asc' | 'desc'][]

export default ({
  emails,
  sorts,
  updateSort,
}: {
  emails: PartialEmail[]
  sorts: SortConfig
  updateSort: (newSorts: SortConfig) => void
}) => {
  const showSortControlsFor = (key: SortableKeys) => {
    const sort = sorts.length > 0 ? sorts.find(s => s[0] === key) : undefined
    const direction = sort?.[1]
    const index = sort !== undefined ? sorts.indexOf(sort) : undefined

    const toggleSort = () => {
      const newSort = [...sorts]

      if (sort === undefined) {
        newSort.push([key, 'desc'])
      } else if (direction === 'asc') {
        newSort[index!] = [key, 'desc']
      } else {
        newSort[index!] = [key, 'asc']
      }

      updateSort(newSort)
    }

    const clearSort = () => {
      const newSorts = sorts.filter(s => s[0] !== key)
      updateSort(newSorts)
    }

    return sort !== undefined ? (
      <span className="flex">
        <button title="Toggle Sort Direction" onClick={toggleSort}>
          <span className="text-blue-600 p-1">
            {direction === 'desc' ? '▼' : '▲'}
          </span>
        </button>
        <button title="Clear Sort" onClick={clearSort}>
          <span className="text-red-600 p-1">x</span>
        </button>
      </span>
    ) : (
      <button onClick={toggleSort}>
        <Icon icon={ChevronUpDownIcon} size="sm" className="p-1" />
      </button>
    )
  }

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
          >
            <span className="flex items-center">
              <span>Status</span>
              {showSortControlsFor('Status')}
            </span>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="flex items-center">
              <span>Message</span>
              {showSortControlsFor('Subject')}
            </span>
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span>
              <span className="hidden xl:flex items-center">
                <span>Recipient</span>
                {showSortControlsFor('Recipient')}
              </span>
              <span className="flex xl:hidden items-center">
                <span>Delivery Info</span>
                {showSortControlsFor('ReceivedAt')}
              </span>
            </span>
          </th>
          <th
            scope="col"
            className="hidden xl:block px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            <span className="flex items-center">
              <span>Received At</span>
              {showSortControlsFor('ReceivedAt')}
            </span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {emails.map(_Row)}
      </tbody>
    </table>
  )
}

function _Row({ MessageID, Status, Subject, ReceivedAt, To }: PartialEmail) {
  // init consts
  const receivedAt = new Date(ReceivedAt)
  const fullDate = receivedAt.toUTCString()
  const fullDetailsUrl =
    (POSTMARK_EMAIL_URL ?? '/organisations/email/') + MessageID

  // init state
  const [isExpanded, setIsExpanded] = useState(false)
  const [body, setBody] = useState<string | undefined>(undefined)
  const [bodyStatus, setBodyStatus] = useState<
    'not-loaded' | 'loading' | 'loaded'
  >('not-loaded')

  // init query
  const { refetch: fetch } = useQuery(
    ['get-notification-email-details', MessageID],
    getEmailDetails,
    {
      enabled: false,
    }
  )

  return (
    <>
      <tr key={MessageID}>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <_Status status={Status} />
        </td>
        <td className="relative xl:whitespace-nowrap pt-4 pb-2 pl-4 pr-3">
          <div className="font-medium text-gray-900 xl:flex align-center">
            {Subject}
            <a
              title="Open full e-mail details in a new tab"
              target="_blank"
              href={fullDetailsUrl}
            >
              <Icon
                className="p-0.5 pl-1"
                size="sm"
                icon={ArrowTopRightOnSquareIcon}
              />
            </a>
          </div>
          <_ContentDrawer
            status={bodyStatus}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            loadContent={() => {
              setBodyStatus('loading')
              fetch().then(result => {
                setBody(
                  result.data?.HtmlBody ??
                    result.data?.TextBody ??
                    `Couldn't load details for Message: ${MessageID}.`
                )
                setBodyStatus('loaded')
              })
            }}
          />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <span className="xl:hidden text-gray-500">Sent To:</span>{' '}
          <span className="underline">{To[0].Email}</span>
          <div className="xl:hidden pt-1.5">
            <span className="text-gray-500 pr-1">Received At:</span>
            <span className="text-gray-700" title={fullDate}>
              <_DateTime dateTime={receivedAt} />
            </span>
          </div>
        </td>
        <td
          title={fullDate}
          className="hidden xl:table-cell px-3 py-4 text-sm text-gray-500"
        >
          <_DateTime dateTime={receivedAt} />
        </td>
      </tr>
      <_Body
        content={body}
        isExpanded={isExpanded}
        status={bodyStatus}
        messageId={MessageID}
      />
    </>
  )
}

const _DateTime = ({ dateTime }: { dateTime: Date }) => {
  const [day, date, year, timeWithZone] = dateTime
    .toLocaleDateString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    })
    .split(',')
  const [time, timeZone] = timeWithZone.trim().split(' ')

  return (
    <span className="text-gray-700">
      <span className="font-bold">
        {day}, {date}
      </span>
      <span> {year},</span>
      <span className="font-bold"> {time}</span>
      <span> {timeZone}</span>
    </span>
  )
}

const _Status = ({ status }: { status: string }) => {
  const color =
    status === 'Sent' || status === 'Processed'
      ? 'green'
      : status === 'Queued'
      ? 'yellow'
      : 'red'
  const colorClasses = `text-${color}-700 bg-${color}-50 ring-${color}-600/20`
  const classes =
    'rounded-md py-1 mx-1 px-2 text-xs font-medium ring-1 ring-inset' +
    colorClasses

  return <span className={classes}>{status}</span>
}

function _ContentDrawer({
  status,
  loadContent,
  isExpanded,
  setIsExpanded,
}: {
  status: 'not-loaded' | 'loading' | 'loaded'
  loadContent: () => void
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
}) {
  return (
    <button
      title="Load e-mail body and events in the current row."
      className="text-blue-600 hover:text-blue-900 pl-1 text-xs"
      onClick={() => {
        setIsExpanded(!isExpanded)
        if (status === 'not-loaded') {
          loadContent()
        }
      }}
    >
      <Icon
        size="xs"
        className="px-0.5 py-0"
        icon={
          isExpanded
            ? ChevronUpIcon
            : status === 'not-loaded'
            ? ChevronDoubleDownIcon
            : ChevronDownIcon
        }
      />
      <span className="px-0.5 py-0">
        {isExpanded ? 'Hide' : status === 'not-loaded' ? 'Load' : 'View'} More{' '}
        {status === 'not-loaded' ? '' : '...'}
      </span>
    </button>
  )
}

function _Body({
  content,
  isExpanded,
  status,
  messageId,
}: {
  messageId: string
  isExpanded: boolean
  status: string
  content: string | undefined
}) {
  return (
    <tr
      className={
        'transition-transform duration-200 ease-in-out' +
        (isExpanded ? '' : ' hidden')
      }
    >
      <td colSpan={4}>
        {status === 'loaded' ? (
          <_Email>
            {content ?? 'Could Not Load Content Of Email With Id: ' + messageId}
          </_Email>
        ) : (
          <span>{'Loading...'}</span>
        )}
      </td>
    </tr>
  )
}

function _Email({ children }: { children: string }) {
  return (
    <Card>
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </Card>
  )
}
