import { Card, Flex } from '@tremor/react'
import { useAuth0 } from '@auth0/auth0-react'
import { acceptTOS, logOut } from '../http'
import OnboardingCard from './card'

export default ({
  user,
  onContinue,
}: {
  user: string | undefined
  onContinue: () => void
}) => {
  return (
    <OnboardingCard email={user}>
      <Flex flexDirection="col" alignItems="center" justifyContent="center">
        <_TOSTitle />
        <_TOSContent />
        <_TOSButtons onContinue={onContinue} />
      </Flex>
    </OnboardingCard>
  )
}

/** @internal */
const _TOSTitle = () => (
  <h2 className="text-xl font-bold underline mb-6">
    Loopwatch Software Terms of Service
  </h2>
)

/** @internal */
const _TOSContent = () => (
  <div>
    <h3 className="text-md font-bold m-2">1. Acceptance of Terms:</h3>
    By accessing and using our software ("Software"), you agree to abide by
    these Terms of Service ("Terms"). If you do not agree to these Terms, please
    refrain from using the Software.
    <h3 className="text-md font-bold m-2">2. License and Usage:</h3>
    We grant you a non-exclusive, non-transferable license to use the Software
    for personal or business purposes, in accordance with these Terms.
    <h3 className="text-md font-bold m-2">3. Restrictions:</h3>
    You agree not to:
    <ul className="text-gray-900 list-disc list-inside">
      <li> Reverse engineer, decompile, or disassemble the Software.</li>
      <li>
        {' '}
        Modify, reproduce, distribute, or create derivative works based on the
        Software.
      </li>
      <li> Use the Software for any illegal or unauthorized purpose.</li>
    </ul>
    <h3 className="text-md font-bold m-2">4. Data Privacy:</h3>
    We may collect and process certain data when you use the Software. Please
    review our Privacy Policy to understand how we handle your data.
    <h3 className="text-md font-bold m-2">5. Updates and Maintenance:</h3>
    We may release updates or patches to improve the Software's functionality.
    You agree to receive these updates automatically.
    <h3 className="text-md font-bold m-2">6. Termination:</h3>
    We reserve the right to suspend or terminate your access to the Software at
    our discretion, without prior notice, if you violate these Terms.
    <h3 className="text-md font-bold m-2">7. Intellectual Property:</h3>
    The Software, including all associated trademarks, copyrights, and
    intellectual property rights, belong to us.
    <h3 className="text-md font-bold m-2">8. Limitation of Liability:</h3>
    To the fullest extent permitted by law, we shall not be liable for any
    direct, indirect, incidental, special, or consequential damages arising from
    your use of the Software.
    <h3 className="text-md font-bold m-2">9. Indemnification:</h3>
    You agree to indemnify and hold us harmless from any claims, losses,
    liabilities, and expenses (including legal fees) related to your use of the
    Software or violation of these Terms.
    <h3 className="text-md font-bold m-2">10. Governing Law:</h3>
    These Terms shall be governed by and construed in accordance with the laws
    of [Jurisdiction], without regard to its conflict of law principles.
    <h3 className="text-md font-bold m-2">11. Changes to Terms:</h3>
    We reserve the right to modify these Terms at any time. Continued use of the
    Software after modifications constitutes acceptance of the new Terms.
    <h3 className="text-md font-bold m-2">12. Entire Agreement:</h3>
    These Terms constitute the entire agreement between you and us regarding the
    Software and supersede any prior agreements.
    <h3 className="text-md font-bold m-2">13. Contact Information:</h3>
    For any questions or concerns regarding these Terms, please contact us at
    [contact email]. By using the Software, you acknowledge that you have read,
    understood, and agreed to these Terms of Service.
  </div>
)

/** @internal */
const _TOSButtons = ({ onContinue }: { onContinue: () => void }) => {
  const { logout } = useAuth0()

  return (
    <>
      <Card className="mt-6 w-72">
        <Flex flexDirection="col" justifyContent="center">
          <Flex justifyContent="center">
            <button
              className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-1 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
              onClick={async () => {
                await acceptTOS()
                onContinue()
              }}
            >
              Accept
            </button>
            <button
              className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-1 mb-1 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
              onClick={() => logOut(logout)}
            >
              Decline*
            </button>
          </Flex>
        </Flex>
      </Card>
      <Card className="mt-6 p-2">
        <Flex justifyContent="center" alignItems="center">
          <i className="text-xs text-gray-500">
            * You must accept the TOS in order to continue to the Loopwatch app,
            or to use any of Loopwatch's services. Clicking "Decline" will log
            you out; and logging in again will redirect you back here until you
            accept the TOS.
          </i>
        </Flex>
      </Card>
    </>
  )
}
